import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

// Components
import FullscreenCarousel from '../../components/FullscreenCarousel';
import PlaylistCarousel from '../../components/PlaylistCarousel';

// Styled Components
import { Wrapper, CurrentPlaylist, PlaylistSelector, ErrorDiv } from './styles';

// Actions
import { fetchActivePlaylist } from '../../store/actions/playlistActions';
import { changeActiveArtwork } from '../../store/actions/artworkActions';
import { fetchMobileQueue } from '../../store/actions/mobileActions';

const Playlist = ({ playlistId, artworkId }) => {
  const dispatch = useDispatch();
  const device = useSelector((state) => state.device);
  const userPlaylists = useSelector((state) => state.userPlaylists);
  const activePlaylist = useSelector((state) => state.activePlaylist);
  const activeArtwork = useSelector((state) => state.artwork.activeArtwork);
  const subscription = useSelector((state) => state.subscription);
  const mobileQueue = useSelector((state) => state.mobileQueue);
  const [playlists, setPlaylists] = useState(userPlaylists.data);
  const [activeComponent, setActiveComponent] = useState('fullscreen');
  const [lastMobileUpdate, setLastMobileUpdate] = useState(null);
  const [showError, setShowError] = useState(false);
  const [prevId, setPrevId] = useState('');
  const [prevUserId] = useState(mobileQueue?.data?.device?._embedded?.user?.id);

  // Component setup
  useEffect(() => {
    dispatch(fetchActivePlaylist(playlistId));
    if (artworkId) {
      dispatch(changeActiveArtwork(artworkId));
    }
    let mobilePlayInterval = null;

    const fetchFromMobile = async () => {
      const deviceId = device?.data?.uid;
      await dispatch(fetchMobileQueue(deviceId));
    };

    mobilePlayInterval = setInterval(fetchFromMobile, 3000);

    return () => {
      clearInterval(mobilePlayInterval);
    };
  }, [playlistId, artworkId]);

  // Listen to changes to the mobile queue and play artwork if needed
  useEffect(() => {
    if (mobileQueue?.data?.updatedAt !== lastMobileUpdate) {
      setLastMobileUpdate(() => mobileQueue?.data?.updatedAt);
      if (mobileQueue?.data?.queue !== null && mobileQueue?.data?.queue?.artworks?.length > 0) {
        dispatch(fetchActivePlaylist(mobileQueue?.data?.queue?.id));
      }
    }
  }, [mobileQueue.data]);

  // Selects the proper playlist and reorders them so that playlist is in the center of the array
  useEffect(() => {
    const pls = userPlaylists.data;
    const targetIndex = pls.findIndex((playlist) => playlist.id === playlistId);
    const centerIndex = Math.floor(pls.length / 2);

    if (targetIndex > centerIndex) {
      const offset = targetIndex - centerIndex;
      const itemsToAppend = pls.splice(0, offset);
      pls.push(...itemsToAppend);
    } else if (targetIndex < centerIndex) {
      const offset = centerIndex - targetIndex;
      const itemsToAppend = pls.splice(-1 + -offset, offset);
      pls.unshift(...itemsToAppend);
    }

    setPlaylists(() => pls);
  }, [userPlaylists]);

  const handleNavigateUp = () => {
    setActiveComponent(() => 'fullscreen');
  };

  const handleNavigateDown = () => {
    setActiveComponent(() => 'playlists');
  };

  const handleSelectPlaylist = async (id) => {
    setActiveComponent(() => 'fullscreen');
    dispatch(fetchActivePlaylist(id));
  };

  const handleNavigateExit = () => {
    window.VIZIO.exitApplication();
  };

  const handleChangeArtwork = (id) => {
    dispatch(changeActiveArtwork(id));
  };
  // Check to verify if device moved to another user

  useEffect(() => {
    let userCheckInterval = null;

    const checkUser = async () => {
      if (prevUserId !== mobileQueue?.data?.device?._embedded?.user?.id) {
        clearInterval(userCheckInterval);
        navigate('/');
      }
    };

    userCheckInterval = setInterval(checkUser, 10000);
  });

  // Empty Collection Check
  useEffect(() => {
    if (activePlaylist.data.artworks.length <= 0) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        if (prevId !== '') dispatch(fetchActivePlaylist(prevId));
      }, 3000);
    } else {
      setPrevId(activePlaylist.data.id);
    }
  }, [activePlaylist]);

  return (
    <Wrapper>
      {showError && (
        <ErrorDiv>
          <p style={{ margin: 0 }}>No Artworks in Collection</p>
        </ErrorDiv>
      )}
      <CurrentPlaylist>
        {!activePlaylist.loading && (
          <FullscreenCarousel
            artworks={activePlaylist?.data?.artworks}
            startingArtwork={artworkId || activeArtwork || activePlaylist?.data?.artworks?.[0]?.id}
            playlistName={activePlaylist?.data?.name}
            hasSubscription={typeof subscription?.data?.plan?.id === 'string'}
            hasFocus={activeComponent === 'fullscreen'}
            onNavigateUp={handleNavigateUp}
            onNavigateDown={handleNavigateDown}
            onChangeArtwork={handleChangeArtwork}
          />
        )}
      </CurrentPlaylist>
      <PlaylistSelector active={activeComponent === 'playlists'}>
        {!activePlaylist.loading && (
          <PlaylistCarousel
            playlists={playlists}
            startingPlaylist={activePlaylist?.data?.id}
            hasFocus={activeComponent === 'playlists'}
            onSelectPlaylist={handleSelectPlaylist}
            onNavigateUp={handleNavigateUp}
            onNavigateDown={handleNavigateDown}
            onNavigateExit={handleNavigateExit}
            onNavigateBack={handleNavigateExit}
          />
        )}
      </PlaylistSelector>
    </Wrapper>
  );
};

Playlist.propTypes = {
  playlistId: PropTypes.string,
  artworkId: PropTypes.string,
};

Playlist.defaultProps = {
  playlistId: null,
  artworkId: null,
};

export default Playlist;
