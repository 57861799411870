import React, { useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';

// Styled Components
import { ErrorWrapper, Logo, ErrorText, RelaunchButton, ErrorDiv } from './styles';

// utils
import colors from '../../util/colors';
import { ENTER } from '../../util/remoteButtons';

const Error = () => {
  const [state, setState] = useState({
    errorState: false,
  });
  const relaunchButton = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      relaunchButton.current.focus();
    }, 800);
  }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      switch (e.keyCode) {
        case ENTER: {
          if (navigator.onLine) {
            navigate('/');
          } else {
            setErrorMessage('No Internet Connection');
            setState((prevState) => ({
              ...prevState,
              errorState: true,
            }));
            setTimeout(() => {
              setState((prevState) => ({
                ...prevState,
                errorState: false,
              }));
            }, 3000);
          }
          relaunchButton.current.focus();
          break;
        }
        default:
          break;
      }
    };
  }, []);

  return (
    <ErrorWrapper>
      <Logo src="https://assets.blackdove.com/images/blackdove-logo-2020.png" alt="" />
      <ErrorText>
        Network Interruption Detected
        <br />
        <br />
        To Exit the app, press EXIT Key on Remote Controller.
        {/* <br />
        Else, the app will EXIT automatically after 60 seconds. */}
        <br />
        OR
        <br />
        To Launch the app again, press OK key on Remote Controller.
      </ErrorText>
      <RelaunchButton ref={relaunchButton} secondary backgroundColor={colors.altAccent}>
        Relaunch
      </RelaunchButton>
      <ErrorDiv style={!state.errorState ? { opacity: '0' } : { opacity: '1' }}>{errorMessage}</ErrorDiv>
    </ErrorWrapper>
  );
};

export default Error;
