import styled from '@emotion/styled';

export const SplashPage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: black;
`;

export const SplashWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  text-align: center;
`;

export const Logo = styled.img`
  width: 40%;
  margin-bottom: 50px;
`;
