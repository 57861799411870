import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import { Router, globalHistory } from '@reach/router';
import ReactGA from 'react-ga';

// Reducers
import reducer from './store/reducer';

// Components
import Layout from './components/Layout';

// Pages
import Splash from './pages/Splash';
import Featured from './pages/Featured';
import Home from './pages/Home';
import Upgrade from './pages/Upgrade';
import Playlist from './pages/Playlist';
import Account from './pages/Account';
import Notice from './pages/Notice';
import License from './pages/License';
import Policy from './pages/Policy';
import Error from './pages/Error';

// Styles
import GlobalStyles from './util/globalStyles';

ReactGA.initialize('UA-55171517-6');
ReactGA.set({ page: window.location.pathname });
ReactGA.pageview(window.location.pathname);

globalHistory.listen(({ location }) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk)));
const persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Layout>
        <GlobalStyles />
        <Router style={{ width: '100%', height: '100%' }}>
          <Splash path="/" />
          <Home path="/home" />
          <Upgrade path="/upgrade" />
          <Account path="/account" />
          <Notice path="/notice" />
          <License path="/license" />
          <Policy path="/policy" />
          <Featured path="/featured" />
          <Featured path="/featured/:artworkId" />
          <Playlist path="/playlist/:playlistId" />
          <Playlist path="/playlist/:playlistId/artwork/:artworkId" />
          <Error path="/error" />
        </Router>
      </Layout>
    </PersistGate>
  </Provider>
);

export default App;
