import {
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  CLEAR_SUBSCRIPTION,
} from '../constants/subscriptionConstants';

const initialState = { data: {}, errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_REQUEST:
      return { ...state, loading: true };
    case FETCH_SUBSCRIPTION_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: [] };
    }
    case FETCH_SUBSCRIPTION_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CLEAR_SUBSCRIPTION:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export default reducer;
