import styled from '@emotion/styled';
import { Spinner } from '@blackdove/ui';

export const CarouselItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: ${({ offset }) => offset}%;
  top: 0%;
  padding: 0px;
`;

export const CarouselItemInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const VideoPoster = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ videoPlaying }) => (videoPlaying ? '0' : '1')};
`;

export const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ArtistName = styled.span`
  position: absolute;
  bottom: 30px;
  left: 40px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 22px;
`;

export const BlackdoveLogo = styled.img`
  position: absolute;
  bottom: 30px;
  right: 40px;
  width: 20%;
`;

export const DebugOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  z-index: 1000;
  max-height: 100%;
`;

export const DebugSection = styled.div`
  margin-bottom: 10px;
`;

export const DebugItem = styled.pre`
  color: ${({ active }) => (active ? '#2AD177' : 'white')};
  font-family: monospace;
  margin: 0;
  padding: 0;
`;

export const VideoLoader = styled(Spinner)`
  z-index: 1000;
  position: 'absolute';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
