import React, { useEffect, useRef } from 'react';
import Button from '../../components/Button';
import { Wrapper, BackIcon, DetailPage, Model, HeaderLine, Paragraph, H5 } from './styles';

const License = () => {
  const backButton = useRef(null);
  const models = useRef(null);

  window.onkeydown = (e) => {
    const n = 100; // amount of px you want to scroll here
    if (e.keyCode === 38) {
      models.current.scrollTop -= n;
    }
    if (e.keyCode === 40) {
      models.current.scrollTop += n;
    }

    if (e.keyCode === 13) {
      backButton.current.click();
    }

    if (e.keyCode === 8) {
      window.history.back();
    }
  };

  useEffect(() => {
    backButton.current.focus();
  });

  return (
    <Wrapper>
      <DetailPage ref={models} style={{ overflowY: 'hidden', paddingTop: '5%' }}>
        <Model>
          <Button ghost style={{ position: 'fixed', top: '9%', left: '6%' }} to="/register" ref={backButton}>
            <BackIcon />
            Back
          </Button>
          <HeaderLine>Collector End User License Agreement</HeaderLine>
          <H5> COLLECTOR TERMS & CONDITIONS</H5>
          <Paragraph>
            PLEASE READ THESE TERMS OF USE CAREFULLY. THIS AGREEMENT CONTAINS WARRANTY AND LIABILITY DISCLAIMERS. BY
            USING BLACKDOVE, LOGGING INTO OUR WEBSITE OR PURCHASING ARTWORK, YOU ACCEPT AND AGREE TO THE TERMS AND
            CONDITIONS HEREOF WITHOUT ANY MODIFICATIONS, ADDITIONS OR DELETIONS. YOU AGREE TO ABIDE BY AND BE BOUND BY
            THE TERMS DESCRIBED HEREIN AND BY ALL TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE AS WELL AS
            ANY ADDITIONAL TERMS AND RESTRICTIONS PRESENTED IN RELATION TO SPECIFIC CONTENT OR A SPECIFIC PRODUCT,
            SERVICE OR FEATURE OFFERED BY BLACKDOVE (COLLECTIVELY, THE &quot;COLLECTOR TERMS&quot;). IF YOU DO NOT AGREE
            TO THE TERMS OF USE CONTAINED IN THIS AGREEMENT, YOU ARE NOT AUTHORIZED TO USE OUR WEBSITE.
          </Paragraph>

          <H5>THESE COLLECTOR TERMS & CONDITIONS </H5>
          <Paragraph>
            (&quot;Agreement&quot;) are a legal agreement between you (“User”, “Visitor”, “Collector” “You” or “Your”)
            and Blackdove, Inc., its subsidiaries and affiliates (&quot;Blackdove&quot;, &quot;we&quot;, &quot;us&quot;
            or &quot;our&quot;). This is a legal document which sets out your rights and obligations, and those of
            Blackdove (&quot;We&quot; or &quot;Us&quot;), in relation to the use of www.blackdove.co or any web-based
            application that we may provide (collectively, the &quot;Website&quot;) and any other services offered by us
            through the Website. References to &quot;Blackdove&quot; in this Agreement are to Us, the Website, and/or
            the services available through the Website, according to the context.{' '}
          </Paragraph>
          <H5>COLLECTORS AND VISITORS</H5>
          <Paragraph>
            By accessing our Website, you accept that you are entering into a binding contract with Us, which is
            governed by the terms and conditions contained herein. Once you register to use Our Website, You establish
            an &quot;Account&quot;, and become a &quot;Collector&quot;. Visitors to Blackdove who do not become
            Collectors (&quot;Visitors&quot;), but who nevertheless use the Website, acknowledge and agree that they are
            bound by this Agreement each time they access Blackdove.
          </Paragraph>
          <H5> WHAT WE DO</H5>
          <Paragraph>
            We provide a portal to collect, view and display artwork in a digital format through Our distribution
            network (“Blackdove Network”), including but not limited to the Website, authorized-third party websites,
            various end-user interfaces, galleries and exhibition spaces owned or used by Us or Our Customers. We
            license digital artwork (&quot;Artwork&quot;) from local, national and international artists
            (&quot;Artists&quot;). You may search and view those Artworks, access Artist profiles and &quot;public&quot;
            exhibitions and may use any other services that we generally make available to Visitors. Collectors can, in
            addition, receive personalized recommendations of Artwork, receive news and other updates from Artists,
            receive special invitations to events and gallery openings, create and view online collections of Artwork
            and use any other services that we generally offer to Collectors. Collectors may “purchase” digital Artwork
            subject to the terms and conditions hereof. We reserve the right to add or delete features as Our website
            evolves and changes over time. For example, we may introduce a right for Collectors to rent individual
            Artworks from us, pay a periodic fee for subscription-based access to galleries, and create a secondary
            marketplace to resell the Artwork to third-party Collectors (collectively, the “Services”). In order to
            obtain Usage Rights and make private use of Artwork, Collectors must have a registered Blackdove Account.{' '}
          </Paragraph>
          <H5>OPENING YOUR ACCOUNT</H5>
          <Paragraph>
            We may ask You and Collectors for information about you and each user when you register for Blackdove
            (“Registrants”). You, as a registrant, must ensure that this information are accurate and current at all
            times. You, as a registrant, shall not include in Your online identity or Collector Account anything that
            could potentially infringe anyone&lsquo;s rights or which is intended to confuse, or which is offensive,
            defamatory or otherwise inappropriate, as determined by Us, in Our sole and absolute discretion. We further
            reserve the right to change information in a Collector’s profile if We deem its offensive, in Our sole and
            absolute discretion. Collectors agree that they are each responsible for everything which is done on or
            through Our Website through or in connection with their Account, while logged on as a Collector to Our
            Website, or through their e-mail address(es). Collectors must ensure that the e-mail address we have on file
            is kept up-to-date and that you have full access to it. By using the Website, you expressly consent to
            electronic communications. Please note that all important and legal notifications from Us will be sent by
            electronic communication. In order to create a Collector Account, you certify that you are at least eighteen
            (18) years of age.{' '}
          </Paragraph>
          <H5>WHAT DO OUR COLLECTORS PURCHASE</H5>
          <Paragraph>
            Subject to the restrictions contained in Paragraph 6 hereof, when a Collector “purchases” Artwork, a
            Collector obtains a limited, non-exclusive perpetual right to use the Artwork subject to the terms and
            conditions hereof (“Usage Rights”). Rentals of Artwork are Usage Rights which expire after a period of time
            as set forth in the terms and conditions of each rental. Usage Rights are obtained once We place the
            purchased Artwork and its Certificate of Authenticity, the name of the Artwork, the Artist’s name and the
            Collector&lsquo;s name, in the digital storage file that We allocate to the relevant Collector’s Account on
            our Website (your, &quot;Private Gallery&quot;). Usage Rights grant each Collector a perpetual, limited
            license to (i) access, use and display the Artwork in its Private Gallery and (ii) display the Artwork’s
            Certificate of Authenticity on up to five (5) devices owned or controlled by the Collector that is capable
            of doing so. The method of using, displaying or accessing each Artwork will vary according to the nature of
            the Artwork in question.
          </Paragraph>
          <H5>CONDITIONS OF PURCHASE</H5>
          <Paragraph>
            The purchase of an Artwork, and thus the Collector’s Usage Rights, are conditional upon the purchasing
            Collector’s payment to us of the price payable to us for that Artwork (and any applicable sales tax
            thereon). A Collector’s Usage Rights do not permit the Collector to, and each Collector agrees not, directly
            or indirectly, to authorize, permit or be involved in (a) printing or creating any other physical
            representation of an Artwork, (b) projecting the Artwork so that it is viewable other than directly on the
            screen of the Collector’s device except in the context of personal and domestic use (c) distributing or
            transmitting Artwork to third parties, or making it available for the use of others (including, but not
            limited to internet or file transmissions), or (d) exhibiting, distributing, re-selling, licensing or
            otherwise commercially exploiting the Artwork or (c) using the Artwork in a way which limits Artist’s rights
            on it or in whatsoever way that is not permitted under the Law of Copyright or this Agreement. Usage Rights
            are to be enjoyed only on or through devices that are owned or controlled by the Collector. If a Collector
            wishes to discuss commercially exploiting an Artwork, it may request such rights through the contact portal
            in each Collector’s Account. Artwork may not be compatible with all devices, operating systems and media.
            Collectors must ensure that the purchased Artwork is compatible with the devices, operating systems and
            media used by the Collector (whether the Collector is the purchasing Collector him/herself or someone else)
            before purchasing it by consulting our Frequently Asked Questions. Any problem arising from the
            compatibility of the Artwork with any devices, operating systems or media after the purchase is intended to
            be upon Your responsibility. We have the right to include invisible digital watermarking or other coding in
            Artwork in order to enable the linking of an Artwork to its Certificate of Authenticity and to track
            potential infringements of an Artist’s rights. You acknowledge that the purchase or rental price of an
            Artwork may vary from time to time and may be Changed in Our sole discretion based on the number of pieces
            sold, popularity of an Artist and other factors that We may take under consideration. We expressly reserve
            the right to cease the Sale or Commercialization of an Artwork at any time. Such termination of Sale or
            Commercialization shall not affect a Collector’s Usage Rights but shall mean only that it shall no longer be
            available for further purchase or rental on the Website.{' '}
          </Paragraph>
          <H5>HOW WE OPERATE</H5>
          <Paragraph>
            We expressly reserve the right to withdraw or modify all or part the Services we offer where we have legal
            or commercial reasons to do so. There may also be times when the operation of Our Website is adversely
            affected or becomes inaccessible as a result of technical difficulties experienced by Our Network, on the
            Internet or other communications networks, or other factors that are beyond our reasonable control. We
            cannot and do not guarantee continuous, uninterrupted or secure access to our Website or any of the material
            or Artwork that appears on it. If we are conducting a period of planned unavailability of Our Website, we
            will inform Customers and Artists of this by a notice that appears on one or more parts of our Website.
            Furthermore, for security or other reasons, we may require Artist or Collectors change their password or
            other information in their Account. You are solely responsible for maintaining the confidentiality of your
            password, login credentials and any additional identifying information.{' '}
          </Paragraph>
          <H5>COLLECTOR CONTENT</H5>
          <Paragraph>
            By posting, contributing, distributing, communicating or transmitting any content on the Website or in a
            Collector’s Account (“Collector Content”), a Collector expressly grants to us a non-exclusive, royalty-free,
            irrevocable license, including the right to grant sub-licenses, to use, reproduce, adapt and distribute that
            material worldwide through Our Network and any other interactive services through which we make Our Website
            available. For the avoidance of doubt, the license granted under this Paragraph will survive any termination
            of this Agreement or any cancellation, suspension or lapse of the relevant Account. We may modify any
            material associated with a Collector or an Account in order to conform it to Our Website or the terms of
            this Agreement. Artists should note that the above paragraph does not apply to the Artwork that they license
            to Us, which are governed by the terms of the separate Artwork Licensing Agreement that they enter into with
            Us, which is incorporated herein by reference. You acknowledge that all copyright, trademarks, and other
            intellectual property rights in and relating to Our Website (including, without limitation, Collector
            Content associated Collectors other than you) are owned by, or licensed to, Us. You may use and access Our
            Website and that Collector Content only to the extent required for the use of the Services in accordance
            with this Agreement, and for the purpose that we make them available. It is expressly prohibited to copy,
            distribute, show in public or create any derivative work from Our Website, or any of the material which is
            found on it unless properly licensed to do so by Us. Collectors agree not to use any robot, spider, scraper
            or other automated means to access Our Website for any purpose without Our prior, express written
            permission. Material posted, contributed, distributed, communicated, transmitted or linked-to by a Collector
            on, through or in connection with Our sites or Services is treated as Collector Content, as herein defined,
            for the purposes of this Agreement. We may treat anything done through a Collector&lsquo;s Account, or by
            means of an e-mail address, phone number or other communications method associated with that Account, as
            having been done by the Collector. Each Collector shall ensure its Collector Content does not; include
            anything that is false, inaccurate, misleading, offensive, abusive, threatening or defamatory, or that is
            likely to cause needless annoyance, inconvenience or distress to any person; contain any computer virus,
            macro virus, Trojan horse, worm, or anything else designed to interfere with, interrupt, or disrupt the
            normal operating procedures of a computer or to surreptitiously intercept, access without authority, or
            expropriate any system, data or personal information; contravene any applicable law (including, without
            limitation, any criminal law) or regulation; infringe the rights of any person or entity; misrepresent the
            Collector&lsquo;s identity in any way; publish or display any material containing personally identifying
            information about another Collector or Artist, such as their real name, address, phone number, or email
            address, except with the prior written approval of that Collector or Artist; or impose an unreasonable or
            disproportionately large load on our infrastructure or interfere with the proper working of Our website. We
            are not responsible for Collector Content. We only provide the technical means, and a venue, for Collector
            Content to be accessed. we do not pre-screen Collector Content. Even though Collectors are prohibited from
            doing so, some may provide information, or otherwise behave, in a way that is unreliable, illegal, or in
            breach of a Collector&lsquo;s obligations under this Agreement. Therefore, you should exercise no lesser
            degree of caution in using the Services than you would when conducting similar activity offline. To the
            extent that the law permits, you release Us, our agents, directors, officers, shareholders and employees
            from all liability arising out of or in connection with any Collector Content. You must not assume that the
            import or export, or the advertising, offering, purchase or sale, of any Artwork or other product complies
            with applicable law or regulation by reason of it appearing on Our Website. We do not have any
            responsibility or liability for the deletion of, or any failure to store or deliver, Collector Content. We
            may set limits on the volumes of specific types of Collector Content that you may send, post, store, or
            receive through Our Website, and reserve the right to delete Collector Content that exceeds such limit(s) in
            our reasonable discretion without any liability whatsoever. It is your responsibility to check Our Website
            as to what such limits are permissible at any given time.{' '}
          </Paragraph>
          <H5>ARTIST COPYRIGHTS</H5>
          <Paragraph>
            You expressly acknowledge and agree that the artist who created the Artwork continues to own the copyright
            in it and has asserted its moral right to be identified as the author of the Artwork. Collectors purchase
            Usage Rights rather than the copyright in their Artwork. The terms and conditions under which We license
            Artwork from Artists are governed by the terms of a licensing agreement entered into with Artist.{' '}
          </Paragraph>
          <H5>OUR PRIVACY POLICY</H5>
          <Paragraph>
            {' '}
            You expressly consent to our Privacy Policy, which governs all users of Our Services and Website, including
            Artists, Visitors and Collectors. If you do not wish your personal data to be handled in accordance with our
            Privacy Policy, then you must not use our Website or our Services. Given the global nature of the World Wide
            Web, please note that a posting on Our Website may be accessible to internet users around the world.
            Collectors shall not use the Services in connection with the sending of any unsolicited messages, or to
            harvest or otherwise collect information about Collectors, including e-mail addresses, without their
            express, written consent. No Collector shall use any information regarding other Collectors that is
            accessible through the Services except as expressly permitted by this Agreement.{' '}
          </Paragraph>
          <H5>THIRD PARTY AND ADDITIONAL SERVICES</H5>
          <Paragraph>
            Our website may contain links to or advertisements for other sites, services or products (&quot;Third Party
            Products&quot;). Third Party Products are provided solely as a convenience to you and may contain materials
            which you find offensive, objectionable, unlawful or inaccurate. Such third-party sites are governed by
            their own terms of use and privacy policies which may differ from this Agreement or those of Our Website.
            The inclusion of links to Third Party Products does not indicate that we endorse those products or services.
            We are not responsible for examining or evaluating the content of third-party sites. We, our affiliates or
            contracted third parties may also offer new, updated or additional services (“Additional Services”) through
            Our website from time to time. Your use of those Additional Services will be governed by this Agreement but
            may also be subject to additional terms and conditions, which will be posted from time to time and which
            Collectors must comply with. By continuing to use Our website, you expressly consent to any additional terms
            and conditions associated with the Additional Services.
          </Paragraph>
          <H5>SUSPENSION OF ACCOUNTS</H5>
          <Paragraph>
            We reserve the right at any time and without notice (a) to suspend or terminate your ability to access Our
            Website, (b) your use of all of part of the Services, (c) to remove from access via Blackdove any Collector
            Content associated with a Collector or its Account, (d) to warn Collectors and other users of Our Website
            against interacting with a particular Collector, and/or (e) to take technical and legal steps to stop any
            Collectors from using it if they appear to us to be in breach of any provision of this Agreement. Should we
            do so, you acknowledge that we shall not incur any liability to you or to any other person. If you see or
            experience anything on Our Website that appears to infringe any provision of this Agreement, please Contact
            Us. We rely on Our Collectors, Visitors and Artists to alert us to infringing content and other undesirable
            or illicit activity. Until we discover the presence of such activities, we are not liable for their presence
            on Our website. In addition to the other rights of suspension specified in this Agreement, We are entitled
            to suspend provision of all or part of Our Services, or the availability of Artwork or other content
            associated with you, at any time if We are obligated or advised to comply with an order, instruction or
            request of any governmental agency, court or other competent authority, or if We are advised by counsel that
            such suspension would protect Our website, an Artist or a Collector, where applicable.
          </Paragraph>
          <H5>LIMITS ON OUR LIABILITY</H5>
          <Paragraph>
            We agree to use reasonable care to provide our Services in accordance with this Agreement. The foregoing
            notwithstanding, all Services provided by Us are provided on an &quot;AS IS, WHERE IS&quot; basis. We do not
            provide any warranties of merchantability or fitness for a particular purpose and shall have no obligation,
            duty or liability whatsoever in contract, tort, including but not limited to negligence, breach of statutory
            duty and any other tort, or otherwise, unless expressly provided in this Agreement. we shall have no
            liability other than for direct loss or damage, whether in contract, tort including but not limited to
            negligence, breach of statutory duty or other tort, or otherwise and whether caused by its act or omission
            or that of its agents, directors, officers, shareholders, employees and subcontractors. In the event that an
            Artwork is required to be removed from Our Website or Gallery for any reason, including but not limited to a
            judgment from any applicable governing body or, if in the opinion of counsel, it is necessary or desirable
            for Us to do so, such that a Collector may no longer exercise its Usage Rights, you expressly acknowledge
            and agree that Our aggregate liability to a Collector shall never exceed the purchase price paid for the
            Artwork, the foregoing being a Collector’s sole and exclusive remedy against Us. We, Our agents, directors,
            officers, shareholders, employees and subcontractors will not be liable to a Collector, Visitor or any third
            party, whether in contract, tort (including negligence, breach of statutory duty or other tort) or otherwise
            (i) for any loss of revenue, data, business, anticipated savings, profits, opportunity, goodwill or
            reputation, or for any business interruption; or (ii) for any indirect, special or consequential loss
            damage, costs or other claims, howsoever caused or arising. Notwithstanding anything contained herein to the
            contrary, all representations, warranties, conditions and other terms, whether express or implied (by common
            law, statute, collaterally or otherwise) are hereby excluded, except in the case of fraud, or where such
            exclusion is not permitted by law. We shall not be liable for any failure to perform our obligations under
            this Agreement caused by matters beyond our reasonable control. The provisions of this Paragraph shall
            survive the expiration or earlier termination of this Agreement.{' '}
          </Paragraph>
          <H5>
            NOTWITHSTANDING ANYTHING CONTAINED TO THE CONTRARY, WE SHALL NOT BE LIABLE WITH RESPECT TO ANY SUBJECT
            MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY
            FOR (i) ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES; OR (ii) COST OF PROCUREMENT OF SUBSTITUTE GOODS,
            TECHNOLOGY OR SERVICES. THIS LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY
            REMEDIES PROVIDED HEREIN.{' '}
          </H5>
          <H5>INDEMNITIES</H5>
          <Paragraph>
            To the fullest extent permitted by law, you shall indemnify and hold Our harmless together with its
            officers, directors, shareholders, employees, contractors, attorneys, agents and representatives from and
            against all claims, damages, losses and expenses of any kind including but not limited to attorney&lsquo;s
            fees, arising out of or resulting from your performance or obligations under this Agreement caused in whole
            or in part by an negligent act or omission, including but not limited to any violation of the terms of this
            Agreement. Such obligation shall not be construed to negate, abridge, or otherwise reduce any other right or
            obligation of indemnity, which would otherwise exist as to any party or person, described herein. You
            further covenant to pay, in advance, for any and all expenses or disbursements of any nature (including all
            attorneys’ fees and costs) incurred by Us or our representatives for any loss or damage suffered as a result
            of or in connection to, any claims, judgments, proceedings or claim is established against it by any person
            in connection with anything done to fulfil the purposes and obligations under this Agreement or in the event
            any party brings an action against Us based on your performance under this Agreement.
          </Paragraph>
          <H5>INTERNATIONAL USE OF BLACKDOVE</H5>
          <Paragraph>
            Collectors must ensure that they comply with the any and all applicable laws, including as to Collector
            Content as well as the Artwork that may be viewed, purchased and displayed through Our website. Usage Rights
            may be prohibited or restricted in your jurisdiction. It is the sole responsibility of each Collector to
            determine the legality of such Usage Rights in its jurisdiction. Nothing contained herein shall cause Us to
            be liable in an event a Collector violates laws in effect in their jurisdiction.{' '}
          </Paragraph>
          <h5>MAINTAINING YOUR ACCOUNT</h5>
          <Paragraph>
            We reserve the right to lapse a Collector&lsquo;s Account if the Collector does not log into its Account for
            a period of one hundred eighty (180) days. If a Collector cancels its Account or allows it to lapse, or in
            the event we cancel such Account for any reason, you remain responsible for performing your obligations
            under this Agreement, and the rights granted to us under this Agreement shall not be terminated as a result.
            Upon the cancellation or lapse of an Account, the name used by the Collector for the associated Account will
            be automatically be ceded to Us and will no longer be associated with or controlled by the former Collector
            in question. We shall be entitled to delete any and all Collector Content associated with that Account.{' '}
          </Paragraph>
          <H5>ASSIGNMENT OF THIS AGREEMENT</H5>
          <Paragraph>
            We reserve the right to assign this Agreement and to assign or subcontract any or all of our rights and
            obligations under this Agreement but will not do so in such a way as to reduce any guarantees you are given
            under this Agreement. Upon the assignee&lsquo;s execution of a covenant directly with the Collector to
            perform Our obligations under this Agreement, you agree that We shall thereupon be released from such
            obligations and there shall be deemed to be a renewal of this Agreement, on identical terms and conditions,
            between you and such assignee. You may not, without the express, prior written consent of Us, assign or
            dispose of this Agreement or any of its rights and obligations hereunder.{' '}
          </Paragraph>
          <H5>CHANGES TO THESE TERMS & CONDITIONS</H5>
          <Paragraph>
            We reserve the right to change this Agreement from time to time and post the new version on Our Website. The
            new version of this Agreement will take effect on the date falling thirty (30) calendar days after the date
            of such posting (or such later date as we indicate in the relevant posting), if any of the changes is to an
            operative provision of this Agreement which is capable of adversely affecting you or immediately upon the
            date of posting or such later date as we indicate in the relevant posting, if the changes are not capable of
            adversely affecting you, examples of which would include, without limitation: (i) changing the name of or
            the web address (www.blackdove.co) that you use to access Our website, or (ii) the refinement of provisions
            that are already included or referred to in this Agreement. In either case, if you do not wish to be
            governed by the new version of this Agreement, you must send Us a Termination Notice and immediately cease
            to use Our website. We shall not have any liability to you in such an event.
          </Paragraph>
          <H5>MISCELLANEOUS</H5>
          <Paragraph>
            The expiration or termination of this Agreement shall not affect those provisions, and the rights and
            obligations therein, set forth in this Agreement which either, by their terms state, or evidence the intent
            of the parties, that the provisions survive the expiration or termination of the Agreement, or must, in
            fact, survive to give effect to the provisions of this Agreement. During the term of this Agreement, we
            shall keep accurate books of account and records covering all transactions relating to this Agreement and
            the Sale of the Artwork at its principal place of business for not less than three (3) years after the
            expiration or earlier termination of this Agreement. If anyone contacts us in relation to Collector Content
            or a transaction associated with a Collector, or any related activity or communication, then the relevant
            Collector agrees to provide all reasonable information and assistance we may require in connection with
            responding to that contact and to respond promptly and accurately to it, should we pass the message to the
            Collector for a response. This Agreement shall not render you an employee, partner, independent contractor,
            or joint venture with Us for any purpose. Nothing in this Agreement shall serve to cause either party to be
            liable for the acts or omissions of the other party as to any matter other than those specifically set forth
            herein. This Agreement and all questions of interpretation, construction and enforcement hereof, and all
            controversies arising hereunder, shall be governed by the applicable statutory and common law of the State
            of Florida. In the event of a dispute between the Parties regarding the terms of this Agreement, prior to
            initiating any cause of action, the Parties hereby agree to submit any dispute to non-binding mediation.
            Venue for any legal proceedings shall be in Miami-Dade County, Florida. In the event any term or provision
            of this Agreement shall be held illegal, unenforceable or inoperative as a matter of law, the remaining
            terms and provisions of this Agreement shall not be affected thereby, but each such term and provision shall
            be valid and shall remain in full force and effect. This Agreement shall be binding upon, and shall inure to
            the benefit of, the successors and assigns of the parties hereto. This Agreement embodies the entire
            agreement between the parties hereto and supersedes any and all prior agreements and understandings, written
            or oral, formal or informal. No modifications or amendments to this Agreement, of any kind whatsoever, shall
            be made or claimed by any party hereto, and no notices of extension, change, modification or amendment made
            or claimed by any party hereto shall have any force or effect whatsoever unless the same shall be endorsed
            in writing and fully signed by the party against whom enforcement is sought. Captions and Paragraph headings
            contained in this Agreement are for convenience and reference only and in no way define, describe, extend or
            limit the scope or intent of this Agreement nor the intent of any provision hereof. In the event of any
            dispute or litigation between the parties of this Agreement relating to or arising out of this Agreement,
            the prevailing party shall be entitled to an award of reasonable attorneys’ fees and costs at the pretrial,
            trial and appellate levels. The Parties hereto agree to take all such action as may be reasonably required
            by any party to effectuate the terms and provisions of this Agreement and the transactions contemplated
            herein. In giving meaning to this Agreement, the singular shall be held to include the plural, the plural
            shall be held to include the singular, and the use of any gender shall be held to include every other and
            all genders. The Parties mutually represent and warrant that they have full power and authority to
            negotiate, execute and perform the terms and provisions of this Agreement. The Parties hereto acknowledge
            and agree that each is foregoing certain rights and assuming certain duties and obligations, which, but for
            this Agreement, would not have been foregone or assumed. Accordingly, the Parties agree that this Agreement
            is fully and adequately supported by consideration and is fail&lsquo; and reasonable in all of its terms. No
            waiver of any provisions of this Agreement shall be valid unless in writing and signed by the party against
            charged.
          </Paragraph>
        </Model>
      </DetailPage>
    </Wrapper>
  );
};

export default License;
