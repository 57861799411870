import styled from '@emotion/styled';
import colors from '../../util/colors';

export const StyledSoundCloudButton = styled.button`
  position: relative;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 12px;
  text-decoration: none;
  margin: 0;
  padding: 5px;
  transition: 150ms ease-in;
  outline: none;
  cursor: pointer;

  &:hover {
    border: 1px solid ${() => colors.white};
  }

  &:focus {
    border: 1px solid ${() => colors.white};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
