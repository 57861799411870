/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { navigate, useParams } from '@reach/router';

// Components
import FullscreenCarouselItem from '../FullscreenCarouselItem';
import HomePlaylistCarousel from '../HomePlaylistCarousel';
import SoundCloudPlayer from '../SoundCloudPlayer';

// Util
import { LEFT, RIGHT, UP, DOWN, ENTER, BACK, EXIT } from '../../util/remoteButtons';
import colors from '../../util/colors';

// Styled Components
import {
  FullscreenFreemiumWrapper,
  FullscreenNavOverlay,
  NavPrev,
  NavNext,
  NavCurrent,
  NowPlayingTitle,
  NowPlayingArtist,
  AccountButton,
  SoundCloudIndicator,
  UpgradeButton,
  PreviousIcon,
  NextIcon,
  PlaylistSelector,
  ErrorDiv,
  // PixelRatio,
} from './styles';

// Actions
import { fetchHomePlaylist } from '../../store/actions/playlistActions';
import { pauseSoundCloudStream, resumeSoundCloudStream } from '../../store/actions/soundCloudActions';

const FullscreenCarousel = ({ artworks, startingArtwork, hasSubscription, hasFocus, onChangeArtwork }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const homePlaylist = useSelector((state) => state.homePlaylist);
  const soundCloudState = useSelector((state) => state.soundCloud);
  const playlistId = params?.playlistId || homePlaylist?.data[0]?.playlistId;
  const mobileQueue = useSelector((state) => state.mobileQueue);

  const [state, setState] = useState({
    centerSlide: artworks.findIndex((artwork) => artwork.id === startingArtwork),
    reorderingSlides: false,
    navigating: false,
    selectedButton: 'view',
    navTimeout: null,
    slideTimeout: null,
    newArt: false,
    artworks,
    errorState: false,
  });

  const [activeComponent, setActiveComponent] = useState('fullscreen');
  const [errorMessage, setErrorMessage] = useState('');
  // const [pixelRatio, setpixelRatio] = useState('');

  const accountButton = useRef(null);
  const soundCloudButton = useRef(null);
  const upgradeButton = useRef(null);

  // For Network Disconnection
  const handleConnectionChange = (event) => {
    if (event.type === 'offline') {
      console.log('You lost connection');
      setErrorMessage('No Internet Connection');
      setState((prevState) => ({
        ...prevState,
        errorState: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          errorState: false,
        }));
      }, 3000);
    }
    if (event.type === 'online') {
      console.log('You are Online');
      setErrorMessage('Connection Online');
      setState((prevState) => ({
        ...prevState,
        errorState: true,
      }));
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          errorState: false,
        }));
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(fetchHomePlaylist());
    // setpixelRatio(`Pixel Ratio : ${window.devicePixelRatio}`);
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  }, []);

  const reorderSlides = (slides, targetIndex, centerIndex) => {
    const items = slides.slice(0);

    if (targetIndex > centerIndex) {
      const offset = targetIndex - centerIndex;
      const slidesToAppend = items.splice(0, offset);
      items.push(...slidesToAppend);
    } else if (targetIndex < centerIndex) {
      const offset = centerIndex - targetIndex;
      const slidesToPrepend = items.splice(-1 + -offset, offset);
      items.unshift(...slidesToPrepend);
    }

    return items;
  };

  const navigateRight = () => {
    clearTimeout(state.navTimeout);
    const slides = state.artworks.slice(0);
    slides.push(slides.shift());

    const navTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, navigating: false }));
    }, 4000);

    setState((prevState) => ({ ...prevState, navTimeout, artworks: slides }));

    onChangeArtwork(slides[state.centerSlide].id);
  };

  const navigateLeft = () => {
    clearTimeout(state.navTimeout);
    const slides = state.artworks.slice(0);
    slides.unshift(slides.pop());

    const navTimeout = setTimeout(() => {
      setState((prevState) => ({ ...prevState, navigating: false }));
    }, 4000);

    onChangeArtwork(slides[state.centerSlide + 1].id);

    setState((prevState) => ({
      ...prevState,
      navTimeout,
      reorderingSlides: true,
      centerSlide: prevState.centerSlide + 1,
      artworks: slides,
    }));
  };

  const onSelectPlaylist = (id) => {
    if (!hasSubscription) {
      navigate('/upgrade');
    } else {
      navigate(`/playlist/${id}`);
    }
  };

  useEffect(() => {
    let slideInterval = null;
    if (hasSubscription) {
      const interval = mobileQueue?.data?.device?.interval;
      slideInterval = setTimeout(() => {
        navigateRight();
      }, interval); // From Mobile App
    } else {
      slideInterval = setTimeout(() => {
        navigateRight();
      }, 10 * 60 * 1000); // 10 minutes
    }

    return () => clearTimeout(slideInterval);
  }, [state.artworks, mobileQueue?.data?.device?.interval]);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  // Reorders artwork so that our startingArtwork is the center most artwork.
  useEffect(() => {
    const targetIndex =
      startingArtwork != null
        ? state.artworks.findIndex((artwork) => artwork.id === startingArtwork)
        : getRandomInt(state.artworks.length - 1);
    const centerIndex = Math.floor(state.artworks.length / 2);
    const slides = reorderSlides(state.artworks, targetIndex, centerIndex);

    setState((prevState) => ({
      ...prevState,
      centerSlide: centerIndex,
      reorderingSlides: false,
      artworks: slides,
    }));
  }, []);

  useEffect(() => {
    const targetIndex =
      startingArtwork != null
        ? state.artworks.findIndex((artwork) => artwork.id === startingArtwork)
        : getRandomInt(state.artworks.length - 1);
    const centerIndex = Math.floor(artworks.length / 2);
    const slides = targetIndex === -1 ? artworks : reorderSlides(artworks, targetIndex, centerIndex);

    setState((prevState) => ({
      ...prevState,
      centerSlide: centerIndex,
      reorderingSlides: true,
      newArt: true,
      artworks: slides,
    }));
  }, [artworks]);

  // Controls
  useEffect(() => {
    if (hasFocus && activeComponent === 'fullscreen') {
      window.onkeydown = (e) => {
        if (state.navigating) {
          switch (e.keyCode) {
            case ENTER: {
              switch (state.selectedButton) {
                case 'account': {
                  navigate('/account');
                  break;
                }
                case 'upgrade': {
                  navigate('/upgrade');
                  break;
                }
                default:
                  setState((prevState) => ({ ...prevState, navigating: false, navTimeout: null }));
                  break;
              }
              break;
            }
            case LEFT: {
              if (state.selectedButton === 'upgrade') {
                setState((prevState) => ({ ...prevState, selectedButton: 'soundCloud', navTimeout: null }));
                soundCloudButton.current.focus();
              } else if (state.selectedButton === 'soundCloud' && accountButton.current) {
                setState((prevState) => ({ ...prevState, selectedButton: 'account', navTimeout: null }));
                accountButton.current.focus();
              } else {
                clearTimeout(state.navTimeout);
                navigateLeft();
              }

              break;
            }
            case RIGHT: {
              if (state.selectedButton === 'soundCloud' && upgradeButton.current) {
                setState((prevState) => ({ ...prevState, selectedButton: 'upgrade', navTimeout: null }));
                upgradeButton.current.focus();
              } else if (state.selectedButton === 'account') {
                setState((prevState) => ({ ...prevState, selectedButton: 'soundCloud', navTimeout: null }));
                soundCloudButton.current.focus();
              } else {
                clearTimeout(state.navTimeout);
                navigateRight();
              }

              break;
            }
            case UP: {
              clearTimeout(state.navTimeout);
              const navTimeout = setTimeout(() => {
                setState((prevState) => ({ ...prevState, navigating: false }));
              }, 4000);

              if (hasSubscription) {
                setState((prevState) => ({ ...prevState, selectedButton: 'account', navTimeout }));
                accountButton.current.focus();
              } else {
                setState((prevState) => ({ ...prevState, selectedButton: 'upgrade', navTimeout }));
                upgradeButton.current.focus();
              }
              break;
            }
            case DOWN: {
              clearTimeout(state.navTimeout);
              setActiveComponent('bottomPlaylist');
              break;
            }
            case BACK: {
              window.VIZIO.exitApplication();
              break;
            }
            case EXIT: {
              window.VIZIO.exitApplication();
              break;
            }
            default:
              break;
          }
        } else if (e.keyCode === LEFT || e.keyCode === RIGHT || e.keyCode === BACK || e.keyCode === UP) {
          setState((prevState) => ({ ...prevState, navigating: true }));
        } else if (e.keyCode === DOWN) {
          setActiveComponent('bottomPlaylist');
        } else if (e.keyCode === EXIT) {
          window.VIZIO.exitApplication();
        }
      };
    }
  });

  // Sets proper slide position after slides have been reordered
  useEffect(() => {
    if (state.reorderingSlides && !state.newArt) {
      setState((prevState) => ({
        ...prevState,
        reorderingSlides: false,
        centerSlide: prevState.centerSlide - 1,
      }));
    } else if (state.reorderingSlides && state.newArt) {
      setState((prevState) => ({
        ...prevState,
        reorderingSlides: false,
        newArt: false,
      }));
    }
  }, [state.reorderingSlides]);

  // Goes back to fullscreen after x amount of time
  useEffect(() => {
    if (state.navigating) {
      clearTimeout(state.navTimeout);

      const navTimeout = setTimeout(() => {
        setState((prevState) => ({ ...prevState, navigating: false }));
      }, 4000);

      setState((prevState) => ({ ...prevState, navTimeout, selectedButton: 'view' }));
    }

    return () => {
      clearTimeout(state.navTimeout);
    };
  }, [state.navigating]);

  const onNavigateExit = () => {
    setActiveComponent('fullscreen');
  };

  const slideSize = 100;
  const paddingSize = 0;
  const baseOffset = state.centerSlide * -slideSize + paddingSize;

  const renderSlide = (artwork, index) => {
    const offset = baseOffset + slideSize * index;
    const current = state.centerSlide === index;

    return (
      <FullscreenCarouselItem
        key={`freemium-player-artwork-${artwork.id}`}
        artwork={artwork}
        offset={offset}
        navigating={state.navigating}
        current={current}
        animate={!state.reorderingSlides}
        hasSubscription={hasSubscription}
      />
    );
  };

  const currentArtwork = state.artworks[state.centerSlide];
  const currentArtist = currentArtwork?._embedded?.artist;

  const handleSoundCloudButtonClick = (event) => {
    if (event.type === 'keydown' && event.keyCode === ENTER) {
      if (soundCloudState.isStreaming) {
        dispatch(pauseSoundCloudStream());
      } else {
        dispatch(resumeSoundCloudStream());
      }
    }
  };

  return (
    <FullscreenFreemiumWrapper>
      {artworks && state.artworks.map(renderSlide)}
      {state.navigating && (
        <FullscreenNavOverlay>
          {hasSubscription && (
            <AccountButton ref={accountButton} secondary backgroundColor={colors.altAccent}>
              Account
            </AccountButton>
          )}

          <SoundCloudIndicator
            hasSubscription={hasSubscription}
            onKeyDown={handleSoundCloudButtonClick}
            ref={soundCloudButton}
          />

          {!hasSubscription && (
            <UpgradeButton ref={upgradeButton} secondary backgroundColor={colors.altAccent}>
              Pair QR Code
            </UpgradeButton>
          )}
          <NavPrev>
            <PreviousIcon />
          </NavPrev>
          <NavCurrent>
            <NowPlayingTitle>{currentArtwork?.name}</NowPlayingTitle>
            <NowPlayingArtist>{`${currentArtist?.givenName} ${currentArtist?.surname}`}</NowPlayingArtist>
          </NavCurrent>
          <NavNext>
            <NextIcon />
          </NavNext>
        </FullscreenNavOverlay>
      )}
      <PlaylistSelector active={activeComponent === 'bottomPlaylist'}>
        {!homePlaylist.loading && (
          <HomePlaylistCarousel
            playlists={homePlaylist?.data}
            startingPlaylist={playlistId}
            hasFocus={activeComponent === 'bottomPlaylist'}
            onNavigateExit={onNavigateExit}
            onSelectPlaylist={onSelectPlaylist}
          />
        )}
      </PlaylistSelector>

      <SoundCloudPlayer />
      {/* <PixelRatio>{pixelRatio}</PixelRatio> */}
      <ErrorDiv style={!state.errorState ? { opacity: '0' } : { opacity: '1' }}>{errorMessage}</ErrorDiv>
    </FullscreenFreemiumWrapper>
  );
};

FullscreenCarousel.propTypes = {
  artworks: PropTypes.array,
  startingArtwork: PropTypes.string,
  hasSubscription: PropTypes.bool,
  hasFocus: PropTypes.bool,
  onChangeArtwork: PropTypes.func,
};

FullscreenCarousel.defaultProps = {
  startingArtwork: 0,
  artworks: [],
  hasSubscription: false,
  hasFocus: false,
  onChangeArtwork: () => {},
};

export default FullscreenCarousel;
