import { CHANGE_ACTIVE_ARTWORK } from '../constants/artworkConstants';

const initialState = { activeArtwork: null };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ACTIVE_ARTWORK:
      return { ...state, activeArtwork: action.payload };
    default:
      return state;
  }
};

export default reducer;
