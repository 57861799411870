import { bdReq } from '@blackdove/utils';

import {
  FETCH_MOBILE_QUEUE_REQUEST,
  FETCH_MOBILE_QUEUE_SUCCESS,
  FETCH_MOBILE_QUEUE_FAILURE,
} from '../constants/mobileConstants';

const fetchMobileQueueRequest = () => ({ type: FETCH_MOBILE_QUEUE_REQUEST });
const fetchMobileQueueSuccess = (payload) => ({ type: FETCH_MOBILE_QUEUE_SUCCESS, payload });
const fetchMobileQueueFailure = (errors) => ({ type: FETCH_MOBILE_QUEUE_FAILURE, errors });

export const fetchMobileQueue = (deviceId) => async (dispatch) => {
  dispatch(fetchMobileQueueRequest());
  try {
    const response = await bdReq.get(`/devices/${deviceId}`);
    if (response.success) {
      return dispatch(fetchMobileQueueSuccess(response.body));
    }
    return dispatch(fetchMobileQueueFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchMobileQueueFailure(e));
  }
};
