const colors = {
  white: '#ffffff',
  black: '#000000',
  base: '#171717',
  lighterBase: '#242424',
  accent: '#6173cf',
  altAccent: '#ee1e23',
};

export default colors;
