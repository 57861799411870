import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { darken } from 'polished';
import { Link } from '@reach/router';
import colors from '../../util/colors';

const baseStyles = ({ backgroundColor, color, hoverBackgroundColor }) =>
  css`
    background: ${backgroundColor || colors.altAccent};
    color: ${color || colors.white};
    border: 1px solid ${backgroundColor || colors.altAccent};
    border-radius: 50px;
    display: inline-block;
    margin: 0 10px 10px 0;
    padding: 12px 25px;
    font-size: 13px;
    text-decoration: none;
    cursor: pointer;
    vertical-align: top;
    transition: 0.15s ease-in;
    outline: none;

    &:hover {
      background: ${hoverBackgroundColor || darken(0.08, colors.altAccent)};
      border: 1px solid ${hoverBackgroundColor || darken(0.08, colors.altAccent)};
    }

    &:focus {
      border: 1px solid ${hoverBackgroundColor || colors.white};
    }
  `;

const blockStyles = css`
  display: block;
  width: 100%;
`;

const secondaryStyles = ({ backgroundColor, color, hoverBackgroundColor }) =>
  css`
    background: ${backgroundColor || colors.base};
    color: ${color || colors.white};
    border: 1px solid ${backgroundColor || colors.base};

    &:hover {
      background: ${hoverBackgroundColor || colors.base};
      color: ${color || colors.white};
      border: 1px solid ${hoverBackgroundColor || colors.base};
    }

    &:focus {
      border: 1px solid ${hoverBackgroundColor || colors.white};
    }
  `;

const ghostStyles = ({ backgroundColor, color }) =>
  css`
    background: none;
    border: 1px solid ${backgroundColor || colors.altAccent};
    color: ${color || colors.white};
    box-shadow: none;

    &:hover {
      background: ${backgroundColor || colors.altAccent};
      color: ${color || colors.white};
    }

    &:focus {
      background: ${backgroundColor || colors.altAccent};
      color: ${color || colors.white};
    }
  `;

const circleStyles = css`
  padding: 10px;
`;

const capsStyles = css`
  text-transform: uppercase;
`;

const widthStyles = ({ width }) =>
  css`
    width: ${width}px;
    display: block;
  `;

const centerStyles = css`
  margin: 0 auto 5px auto;
  display: block;
`;

const rightStyles = css`
  margin: 0 0 5px auto;
  display: block;
`;

const disabledStyles = ({ disabledBackgroundColor }) =>
  css`
    background: ${disabledBackgroundColor || colors.submarine};
    border: 1px solid ${disabledBackgroundColor || colors.submarine};
    box-shadow: none;

    &:hover {
      background: ${disabledBackgroundColor || colors.submarine};
      border: 1px solid ${disabledBackgroundColor || colors.submarine};
      cursor: not-allowed;
    }
  `;

export const StyledA = styled.a`
  ${baseStyles}
  ${({ block }) => block && blockStyles}
  ${({ secondary }) => secondary && secondaryStyles}
  ${({ ghost }) =>
    ghost && ghostStyles}
  ${({ circle }) => circle && circleStyles}
  ${({ caps }) => caps && capsStyles}
  ${({
    width,
  }) => width && widthStyles}
  ${({ centered }) => centered && centerStyles}
  ${({ right }) =>
    right && rightStyles}
  ${({ disabled }) => disabled && disabledStyles}
`;

export const StyledLink = styled(Link)`
  ${baseStyles}
  ${({ block }) => block && blockStyles}
  ${({ secondary }) => secondary && secondaryStyles}
  ${({ ghost }) =>
    ghost && ghostStyles}
  ${({ circle }) => circle && circleStyles}
  ${({ caps }) => caps && capsStyles}
  ${({
    width,
  }) => width && widthStyles}
  ${({ centered }) => centered && centerStyles}
  ${({ right }) =>
    right && rightStyles}
  ${({ disabled }) => disabled && disabledStyles}
`;

export const StyledButton = styled.button`
  ${baseStyles}
  ${({ block }) => block && blockStyles}
  ${({ secondary }) => secondary && secondaryStyles}
  ${({ ghost }) =>
    ghost && ghostStyles}
  ${({ circle }) => circle && circleStyles}
  ${({ caps }) => caps && capsStyles}
  ${({
    width,
  }) => width && widthStyles}
  ${({ centered }) => centered && centerStyles}
  ${({ right }) =>
    right && rightStyles}
  ${({ disabled }) => disabled && disabledStyles}
`;
