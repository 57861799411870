import React from 'react';
import PropTypes from 'prop-types';
import { VideoPlayer } from '@blackdove/player';

import { CarouselItem, CarouselItemInner } from './styles';

const FullscreenCarouselItem = ({ offset, current, artwork, animate, navigating, hasSubscription }) => {
  const artist = artwork?._embedded?.artist;

  return (
    <CarouselItem current={current} offset={offset} animate={animate} fullscreen={!navigating}>
      <CarouselItemInner>
        <VideoPlayer
          videoUrl={artwork.media.video.dash}
          posterUrl={artwork.media.image.low.landscape}
          artistName={hasSubscription ? '' : `${artist?.givenName} ${artist?.surname}`}
          showLogo={!hasSubscription}
          initialize={current}
          showDebug={false}
        />
      </CarouselItemInner>
    </CarouselItem>
  );
};

FullscreenCarouselItem.propTypes = {
  offset: PropTypes.number.isRequired,
  current: PropTypes.bool.isRequired,
  navigating: PropTypes.bool.isRequired,
  artwork: PropTypes.object.isRequired,
  animate: PropTypes.bool.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
};

export default FullscreenCarouselItem;
