import {
  FETCH_HOME_PLAYLIST_REQUEST,
  FETCH_HOME_PLAYLIST_SUCCESS,
  FETCH_HOME_PLAYLIST_FAILURE,
} from '../constants/playlistConstants';

const initialState = { data: [], errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOME_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_HOME_PLAYLIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_HOME_PLAYLIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
