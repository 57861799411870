import {
  FETCH_ACTIVE_PLAYLIST_REQUEST,
  FETCH_ACTIVE_PLAYLIST_SUCCESS,
  FETCH_ACTIVE_PLAYLIST_FAILURE,
  CLEAR_ACTIVE_PLAYLIST,
} from '../constants/playlistConstants';

const initialState = { data: {}, errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_PLAYLIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_ACTIVE_PLAYLIST_SUCCESS:
      return { ...state, loading: false, data: action.payload, errors: [] };
    case FETCH_ACTIVE_PLAYLIST_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CLEAR_ACTIVE_PLAYLIST:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export default reducer;
