import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import { LEFT, RIGHT, ENTER, DOWN, EXIT, BACK } from '../../util/remoteButtons';

// Components
import ArtCarousel from '../../components/ArtCarousel';
import PlaylistCarousel from '../../components/PlaylistCarousel';

// Styled Components
import {
  HomeWrapper,
  HomeHeader,
  HomeLogo,
  HomeButton,
  AccountButton,
  FeaturedCarouselWrapper,
  HomeCarouselWrapper,
  HomePlaylistTitle,
  PlaylistName,
} from './styles';

const Home = () => {
  const featuredPlaylist = useSelector((state) => state.featuredPlaylist);
  const userPlaylists = useSelector((state) => state.userPlaylists);

  const [activeComponent, setActiveComponent] = useState('featuredCarousel');
  const [activeNavButton, setActiveNavButton] = useState('home');

  const homeButton = useRef(null);
  const accountButton = useRef(null);

  // TODO: This should be a redux action, listen for the selector data to change
  // Poll the device play queue
  // useEffect(() => {
  //   let mobilePlayInterval = null;

  //   const fetchFromMobile = async () => {
  //     const deviceId = store.get('deviceId');
  //     const user = store.get('authUser');
  //     const id = user.id ? user.id : user;

  //     try {
  //       const data = await bdReq.post(`/users/${id}/devices/${deviceId}/play`);
  //       const sessionLastUpdatedAt = store.get('sessionLastUpdatedAt') || '';
  //       if (data.success) {
  //         if (data.body.sessionLastUpdatedAt !== sessionLastUpdatedAt) {
  //           if (data.body.queue == null) {
  //             return;
  //           }
  //           navigate('/mobile-artwork-player');
  //         }
  //       }
  //       clearInterval(fetchFromMobile);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };

  //   mobilePlayInterval = setInterval(fetchFromMobile, 3000);
  //   return () => {
  //     clearInterval(mobilePlayInterval);
  //   };
  // }, []);

  useEffect(() => {
    if (activeComponent === 'nav') {
      window.onkeydown = (e) => {
        switch (e.keyCode) {
          case LEFT: {
            homeButton.current.focus();
            setActiveNavButton(() => 'home');
            break;
          }
          case RIGHT: {
            accountButton.current.focus();
            setActiveNavButton(() => 'account');
            break;
          }
          case DOWN: {
            setActiveComponent(() => 'featuredCarousel');
            homeButton.current.blur();
            accountButton.current.blur();
            break;
          }
          case ENTER: {
            if (activeNavButton === 'home') {
              homeButton.current.click();
            }

            if (activeNavButton === 'account') {
              accountButton.current.click();
            }
            break;
          }
          case EXIT: {
            window.VIZIO.exitApplication();
            break;
          }
          case BACK: {
            window.VIZIO.exitApplication();
            break;
          }
          default:
            break;
        }
      };
    }
  });

  const handleNavigateUp = () => {
    switch (activeComponent) {
      case 'featuredCarousel':
        setActiveComponent(() => 'nav');
        homeButton.current.focus();
        break;
      case 'playlistCarousel':
        setActiveComponent(() => 'featuredCarousel');
        break;
      default:
        break;
    }
  };

  const handleNavigateDown = () => {
    switch (activeComponent) {
      case 'nav':
        setActiveComponent(() => 'featuredCarousel');
        break;
      case 'featuredCarousel':
        setActiveComponent(() => 'playlistCarousel');
        break;
      default:
        break;
    }
  };

  const handleNavigateExit = () => {
    window.VIZIO.exitApplication();
  };

  const handleSelectPlaylist = (playlistId) => {
    navigate(`/playlist/${playlistId}`);
  };

  return (
    <HomeWrapper>
      <HomeHeader>
        <HomeLogo src="assets/img/logo.png" alt="Blackdove" />
        <HomeButton ghost to="/home" ref={homeButton}>
          Home
        </HomeButton>
        <AccountButton ghost to="/account" ref={accountButton}>
          Account
        </AccountButton>
      </HomeHeader>
      <HomePlaylistTitle>
        <PlaylistName>{featuredPlaylist?.data?.name}</PlaylistName>
      </HomePlaylistTitle>
      <FeaturedCarouselWrapper>
        <ArtCarousel
          artworks={featuredPlaylist?.data?.artworks}
          slideWidth={32}
          page="/featured"
          onNavigateUp={handleNavigateUp}
          onNavigateDown={handleNavigateDown}
          onNavigateExit={handleNavigateExit}
          onNavigateBack={handleNavigateExit}
          hasFocus={activeComponent === 'featuredCarousel'}
        />
      </FeaturedCarouselWrapper>
      <HomeCarouselWrapper>
        <PlaylistCarousel
          playlists={userPlaylists?.data}
          onSelectPlaylist={handleSelectPlaylist}
          onNavigateUp={handleNavigateUp}
          onNavigateDown={handleNavigateDown}
          onNavigateExit={handleNavigateExit}
          onNavigateBack={handleNavigateExit}
          hasFocus={activeComponent === 'playlistCarousel'}
        />
      </HomeCarouselWrapper>
    </HomeWrapper>
  );
};
export default Home;
