import { bdReq, bdAuth } from '@blackdove/utils';

import {
  FETCH_ACTIVE_PLAYLIST_REQUEST,
  FETCH_ACTIVE_PLAYLIST_SUCCESS,
  FETCH_ACTIVE_PLAYLIST_FAILURE,
  FETCH_FEATURED_PLAYLIST_REQUEST,
  FETCH_FEATURED_PLAYLIST_SUCCESS,
  FETCH_FEATURED_PLAYLIST_FAILURE,
  FETCH_USER_PLAYLISTS_REQUEST,
  FETCH_USER_PLAYLISTS_SUCCESS,
  FETCH_USER_PLAYLISTS_FAILURE,
  CLEAR_FEATURED_PLAYLIST,
  CLEAR_USER_PLAYLISTS,
  CLEAR_ACTIVE_PLAYLIST,
  FETCH_HOME_PLAYLIST_REQUEST,
  FETCH_HOME_PLAYLIST_SUCCESS,
  FETCH_HOME_PLAYLIST_FAILURE,
} from '../constants/playlistConstants';

export const clearFeaturedPlaylist = () => ({ type: CLEAR_FEATURED_PLAYLIST });
export const clearUserPlaylists = () => ({ type: CLEAR_USER_PLAYLISTS });
export const clearActivePlaylist = () => ({ type: CLEAR_ACTIVE_PLAYLIST });

const fetchFeaturedPlaylistRequest = () => ({ type: FETCH_FEATURED_PLAYLIST_REQUEST });
const fetchFeaturedPlaylistSuccess = (payload) => ({ type: FETCH_FEATURED_PLAYLIST_SUCCESS, payload });
const fetchFeaturedPlaylistFailure = (errors) => ({ type: FETCH_FEATURED_PLAYLIST_FAILURE, errors });

export const fetchFeaturedPlaylist = () => async (dispatch) => {
  dispatch(fetchFeaturedPlaylistRequest());
  try {
    const tokens = bdAuth.getToken();
    const id = tokens ? tokens.user : 'anonymous';
    const url = `/users/${id}/playlists/Y0d4aGVXeHBjM1E9NzlmMzdkNzAtYTlmZC0xMWU3LWI5ZDMtNTU2OGIzMzVhMDUx`;
    const response = await bdReq.get(url);
    if (response.success) {
      return dispatch(fetchFeaturedPlaylistSuccess(response.body));
    }
    return dispatch(fetchFeaturedPlaylistFailure('Could not fetch featured playlist.'));
  } catch (e) {
    return dispatch(fetchFeaturedPlaylistFailure(e));
  }
};

const fetchUserPlaylistsRequest = () => ({ type: FETCH_USER_PLAYLISTS_REQUEST });
const fetchUserPlaylistsSuccess = (payload) => ({ type: FETCH_USER_PLAYLISTS_SUCCESS, payload });
const fetchUserPlaylistsFailure = (errors) => ({ type: FETCH_USER_PLAYLISTS_FAILURE, errors });

export const fetchUserPlaylists = () => async (dispatch) => {
  dispatch(fetchUserPlaylistsRequest());
  try {
    const tokens = bdAuth.getToken();
    const url = `/users/${tokens.user.id}/home`;
    const response = await bdReq.get(url);
    if (response.success) {
      const playlists = response.body
        .filter((l) => l.type === 'playlist-list' && l.heading === 'Collections')
        .map((item) => item.content)
        .flat();
      return dispatch(fetchUserPlaylistsSuccess(playlists));
    }
    return dispatch(fetchUserPlaylistsFailure('Could not fetch user playlists.'));
  } catch (e) {
    return dispatch(fetchUserPlaylistsFailure(e));
  }
};

const fetchActivePlaylistRequest = () => ({ type: FETCH_ACTIVE_PLAYLIST_REQUEST });
const fetchActivePlaylistSuccess = (payload) => ({ type: FETCH_ACTIVE_PLAYLIST_SUCCESS, payload });
const fetchActivePlaylistFailure = (errors) => ({ type: FETCH_ACTIVE_PLAYLIST_FAILURE, errors });

export const fetchActivePlaylist = (playlistId) => async (dispatch) => {
  dispatch(fetchActivePlaylistRequest());
  try {
    const user = bdAuth.getToken();
    const url = `/users/${user.id}/playlists/${playlistId}`;
    const response = await bdReq.get(url);
    if (response.success) {
      return dispatch(fetchActivePlaylistSuccess(response.body));
    }
    return dispatch(fetchActivePlaylistFailure('Could not fetch playlist.'));
  } catch (e) {
    return dispatch(fetchActivePlaylistFailure(e));
  }
};

const fetchHomePlaylistRequest = () => ({ type: FETCH_HOME_PLAYLIST_REQUEST });
const fetchHomePlaylistSuccess = (payload) => ({ type: FETCH_HOME_PLAYLIST_SUCCESS, payload });
const fetchHomePlaylistFailure = (errors) => ({ type: FETCH_HOME_PLAYLIST_FAILURE, errors });

export const fetchHomePlaylist = () => async (dispatch) => {
  dispatch(fetchHomePlaylistRequest());
  try {
    const url = '/collections';
    const response = await bdReq.get(url);
    const res = response.body.filter((item) => item.media !== undefined);
    const items = res.map((itm) => {
      // const file = itm.Files[0];
      return {
        playlistId: itm.id,
        img: itm.media,
        // ...file
      };
    });

    return dispatch(fetchHomePlaylistSuccess(items));
  } catch (e) {
    return dispatch(fetchHomePlaylistFailure(e));
  }
};
