import styled from '@emotion/styled';
import { ChevronForward, ChevronBack, ChevronDown, ChevronUp } from '@emotion-icons/ionicons-sharp';

import colors from '../../util/colors';
import Button from '../Button';
import SoundCloudButton from '../SoundCloudButton';

export const FullscreenFreemiumWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const FullscreenNavOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
`;

export const NavPrev = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

export const NavNext = styled.div`
  flex: 1;
  text-align: right;
  justify-content: right;
`;

export const NavCurrent = styled.div`
  flex: 2;
  text-align: center;
`;

export const PlaylistName = styled.span`
  color: white;
  display: block;
  font-size: 14px;
  margin-top: 15px;
`;

export const ArtworkName = styled.span`
  color: white;
  display: block;
  font-size: 14px;
`;

export const NowPlayingTitle = styled.span`
  display: block;
  color: white;
  font-size: 32px;
  text-transform: uppercase;
  line-height: 1.2em;
`;

export const NowPlayingArtist = styled.span`
  display: block;
  color: white;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const AccountButton = styled(Button)`
  position: absolute;
  top: 75px;
  left: 110px;
`;

export const SoundCloudIndicator = styled(SoundCloudButton)`
  position: absolute;
  top: 75px;
  right: ${({ hasSubscription }) => (hasSubscription ? '100px' : '300px')};
`;

export const UpgradeButton = styled(Button)`
  position: absolute;
  top: 75px;
  right: 110px;
`;

export const PreviousIcon = styled(ChevronBack)`
  color: ${colors.white};
  width: 40px;
`;

export const NextIcon = styled(ChevronForward)`
  color: ${colors.white};
  width: 40px;
`;

export const UpIcon = styled(ChevronUp)`
  color: ${colors.white};
  width: 40px;
`;

export const DownIcon = styled(ChevronDown)`
  color: ${colors.white};
  width: 40px;
`;

export const PlaylistSelector = styled.div`
  width: 100%;
  height: 36%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: ${({ active }) => (active ? '0' : '-36%')};
  left: 0;
  transition: bottom 0.2s ease-in-out;
`;

export const ErrorDiv = styled.div`
  z-index: 9999;
  position: absolute;
  top: 88%;
  left: 2%;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  background: rgb(238, 30, 35);
  padding: 1%;
  border-radius: 18px;
  opacity: 0;
  transition: 1s;
`;

export const PixelRatio = styled.div`
  z-index: 9999;
  position: absolute;
  top: 4%;
  left: 2%;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
`;
