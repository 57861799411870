import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import { bdReq, bdAuth } from '@blackdove/utils';

// Actions
import { fetchDevice } from '../../store/actions/deviceActions';
import { fetchUser } from '../../store/actions/userActions';
import { fetchFeaturedPlaylist, fetchUserPlaylists, fetchActivePlaylist } from '../../store/actions/playlistActions';
import { fetchSubscription } from '../../store/actions/subscriptionActions';
import { FETCH_DEVICE_SUCCESS } from '../../store/constants/deviceConstants';

// Styled Components
import { UpgradeWrapper, Logo, QRCode, TagLine, BottomApps, BackButton } from './styles';

// utils
import colors from '../../util/colors';
import { ENTER, BACK } from '../../util/remoteButtons';

const Upgrade = () => {
  const dispatch = useDispatch();
  const deviceId = useSelector((state) => state.app.vizioDeviceId);

  const backButton = useRef(null);

  // Listen for device to be paired via the mobile app.
  useEffect(() => {
    let pairInterval = null;
    setTimeout(() => {
      backButton.current.focus();
    }, 800);
    const pair = async () => {
      try {
        const deviceResult = await dispatch(fetchDevice(deviceId));
        if (deviceResult.type === FETCH_DEVICE_SUCCESS) {
          clearInterval(pairInterval);
          const emailId = deviceResult.payload._embedded.user.email;

          // TODO: Move this into a redux action. Functionality is duplicated on the splash page.
          if (!bdAuth.getToken()) {
            const response = await bdReq.post('/emailLogin', { emailId });
            if (response.success) {
              bdAuth._storeToken(response.body);
            }
          }

          await dispatch(fetchUser());
          await dispatch(fetchSubscription());
          await dispatch(fetchFeaturedPlaylist());
          await dispatch(fetchUserPlaylists());
          await dispatch(fetchActivePlaylist('Y0d4aGVXeHBjM1E9NzlmMzdkNzAtYTlmZC0xMWU3LWI5ZDMtNTU2OGIzMzVhMDUx'));

          navigate('/playlist/Y0d4aGVXeHBjM1E9NzlmMzdkNzAtYTlmZC0xMWU3LWI5ZDMtNTU2OGIzMzVhMDUx');
        }
      } catch (e) {
        console.log(e);
      }
    };

    pairInterval = setInterval(() => pair(), 3000);

    return () => {
      clearInterval(pairInterval);
    };
  }, []);

  useEffect(() => {
    window.onkeydown = (e) => {
      switch (e.keyCode) {
        case BACK:
        case ENTER: {
          // backButton.current.focus();
          navigate(-1);
          break;
        }
        default:
          break;
      }
    };
  }, []);

  return (
    <UpgradeWrapper>
      <Logo src="https://assets.blackdove.com/images/blackdove-logo-2020.png" alt="" />
      <BackButton ref={backButton} secondary backgroundColor={colors.altAccent}>
        Back
      </BackButton>
      <QRCode value={deviceId} size={180} renderAs="svg" />
      <TagLine>
        Pair this display with your mobile app
        <br />
        Scan the QR code provided above in the app.
      </TagLine>
      <BottomApps src="assets/img/apps-image.png" alt="" />
    </UpgradeWrapper>
  );
};

export default Upgrade;
