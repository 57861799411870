import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const InfoWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 380px;
  margin: 0 auto;
  padding-top: 100px;
`;

export const Logo = styled.img`
  width: 75%;
  display: block;
  margin: 0 auto 40px auto;
`;

export const Info = styled.span`
  display: inline-block;
  color: white;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 40px;
  text-align: justify;
`;

export const DetailsWrapper = styled.div`
  text-align: left;
  padding: 35px 0 25px 0;
`;

export const DetailLabel = styled.div`
  color: white;
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
`;

export const DetailValue = styled.div`
  color: white;
  font-size: 13px;
  display: block;
`;

export const ButtonWrapper = styled.div``;

export const QRWrapper = styled.div`
  border: 15px solid white;
  position: absolute;
  bottom: 25px;
  left: 25px;
`;

export const VersionWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
  color: white;
`;
