import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FullscreenCarousel from '../../components/FullscreenCarousel';

// Styled Components
import { FeaturedWrapper } from './styles';

const Featured = ({ artworkId }) => {
  const featuredPlaylist = useSelector((state) => state.featuredPlaylist);
  const subscription = useSelector((state) => state.subscription);

  return (
    <FeaturedWrapper>
      <FullscreenCarousel
        artworks={featuredPlaylist?.data?.artworks}
        startingArtwork={artworkId}
        playlistName={featuredPlaylist?.data?.name}
        hasSubscription={typeof subscription?.data?.plan?.id === 'string'}
        hasFocus
      />
    </FeaturedWrapper>
  );
};

Featured.propTypes = {
  artworkId: PropTypes.string,
};

Featured.defaultProps = {
  artworkId: null,
};

export default Featured;
