import {
  FETCH_USER_PLAYLISTS_REQUEST,
  FETCH_USER_PLAYLISTS_SUCCESS,
  FETCH_USER_PLAYLISTS_FAILURE,
  CLEAR_USER_PLAYLISTS,
} from '../constants/playlistConstants';

const initialState = { data: [], errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PLAYLISTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_USER_PLAYLISTS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: [] };
    }
    case FETCH_USER_PLAYLISTS_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CLEAR_USER_PLAYLISTS:
      return { ...state, data: [] };
    default:
      return state;
  }
};

export default reducer;
