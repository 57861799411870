import styled from '@emotion/styled';
import Button from '../../components/Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

export const CurrentPlaylist = styled.div`
  width: 100%;
  height: 100%;
`;

export const ErrorDiv = styled.div`
  background-color: red;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  bottom: 4%;
  left: 4%;
  padding: 1%;
  z-index: 9999;
  border-radius: 50px;
`;

export const PlaylistSelector = styled.div`
  width: 100%;
  height: 36%;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: ${({ active }) => (active ? '0' : '-36%')};
  left: 0;
  transition: bottom 0.2s ease-in-out;
`;

export const AccountButton = styled(Button)``;
