import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
} from '../constants/deviceConstants';

const initialState = { data: {}, errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICE_REQUEST:
      return { ...state, loading: true };
    case FETCH_DEVICE_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: [] };
    }
    case FETCH_DEVICE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case DELETE_DEVICE_REQUEST:
      return { ...state, loading: true };
    case DELETE_DEVICE_SUCCESS: {
      return { ...state, loading: false, data: {}, errors: [] };
    }
    case DELETE_DEVICE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
