import React from 'react';
import { render } from 'react-dom';
import { BlackdoveClient, bdReq, bdAuth } from '@blackdove/utils';
import { installPolyfills } from '@blackdove/player';

import App from './App';

installPolyfills();

bdReq.setApiUrl('https://api.blackdove.io');
bdAuth.setAppId('fdea8d89jufd89kfff');
bdAuth.setUserEndpoint('/me');

BlackdoveClient.setApiUrl('https://api.blackdove.io');
BlackdoveClient.setClientId('fdea8d89jufd89kfff');

const mountApp = document.getElementById('root');
render(<App />, mountApp);

if (process.env.NODE_ENV === 'development') {
  module.hot.accept();
}
