import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { LEFT, RIGHT, ENTER, UP, DOWN, EXIT } from '../../util/remoteButtons';

import Button from '../Button';

// Styled Components
import {
  Wrapper,
  Slide,
  SlideInner,
  SlideArtwork,
  SlideDetails,
  SlideCompactDetails,
  ArtworkName,
  ArtistName,
  PlayButton,
  PlayIcon,
  SlideSize,
} from './styles';

const getSelectedSlide = (align, artworks) => {
  switch (align) {
    case 'left':
      return 2;
    case 'center':
      return Math.floor(artworks.length / 2);
    case 'right':
      return -3;
    default:
      return 0;
  }
};

const getBaseOffset = (align, slideWidth, selectedSlide) => {
  switch (align) {
    case 'left':
      return selectedSlide * -slideWidth + 5;
    case 'center':
      return selectedSlide * -slideWidth + (100 - slideWidth) / 2;
    case 'right':
      return selectedSlide * -slideWidth + (100 - slideWidth - 5);
    default:
      return 0;
  }
};

const ArtCarousel = ({
  artworks,
  playlistId,
  align,
  slideWidth,
  compact,
  hasFocus,
  page,
  onNavigateUp,
  onNavigateDown,
  onNavigateExit,
  onNavigateBack,
}) => {
  const [art, setArt] = useState(artworks);
  const [selectedSlide, setSelectedSlide] = useState(getSelectedSlide(align, artworks));
  const [reordering, setReordering] = useState(false);

  const selectedPlayButton = useRef(null);

  // When art changes, reset the art order
  useEffect(() => {
    setArt(() => artworks);
    setSelectedSlide(() => getSelectedSlide(align, artworks));
  }, [artworks]);

  useEffect(() => {
    if (hasFocus) {
      window.onkeydown = (e) => {
        switch (e.keyCode) {
          case LEFT: {
            const slides = art.slice(0);
            slides.unshift(slides.pop());
            setArt(() => slides);
            setSelectedSlide((prevSelectedSlide) => prevSelectedSlide + 1);
            setReordering(() => true);
            break;
          }
          case RIGHT: {
            const slides = art.slice(0);
            slides.push(slides.shift());
            setArt(() => slides);
            break;
          }
          case ENTER: {
            selectedPlayButton.current.click();
            break;
          }
          case UP: {
            onNavigateUp();
            break;
          }
          case DOWN: {
            onNavigateDown();
            break;
          }
          case EXIT: {
            onNavigateExit();
            break;
          }
          case 8: {
            onNavigateBack();
            break;
          }
          default:
            break;
        }
      };
    }
  });

  // When navigating left, animation is disabled and the slides are then reordered.
  // This is done so the carousel does not "jump" when a slide is inserted onto the front of the array of slides.
  // This hook enables animation and sets the proper selectedSlide after the array has been reordered, which results
  // in a smooth animation when navigating left.
  useEffect(() => {
    if (reordering) {
      setReordering(() => false);
      setSelectedSlide((prevSelectedSlide) => prevSelectedSlide - 1);
    }
  }, [reordering]);

  const baseOffset = getBaseOffset(align, slideWidth, selectedSlide);

  const renderSlide = (artwork, index) => {
    const offset = baseOffset + slideWidth * index;
    const current = art[selectedSlide].id === artwork.id;
    const image = artwork.media.image.low.landscape;
    const artist = artwork._embedded ? artwork._embedded.artist : null;
    const url = playlistId ? `${page}/${playlistId}/${artwork.id}` : `${page}/${artwork.id}`;

    return (
      <Slide key={`art-carousel-slide-${artwork.id}`} offset={offset} slideWidth={slideWidth} animate={!reordering}>
        <SlideInner>
          <SlideSize>
            <SlideArtwork src={image} alt="" current={current} compact={compact} hasFocus={hasFocus} />
          </SlideSize>
          {!compact && (
            <SlideDetails show={current && hasFocus}>
              <ArtworkName>{artwork.name}</ArtworkName>
              {artist && <ArtistName>{`${artist.givenName} ${artist.surname}`}</ArtistName>}
              <PlayButton to={url} ref={current ? selectedPlayButton : null}>
                <PlayIcon />
              </PlayButton>
            </SlideDetails>
          )}
          {compact && (
            <SlideCompactDetails show={current && hasFocus}>
              <ArtworkName compact={compact}>{artwork?.name}</ArtworkName>
              {artist && <ArtistName>{`${artist?.givenName} ${artist?.surname}`}</ArtistName>}
              <Button to={url} ref={current ? selectedPlayButton : null} style={{ display: 'none' }}>
                <PlayIcon />
              </Button>
            </SlideCompactDetails>
          )}
        </SlideInner>
      </Slide>
    );
  };

  return <Wrapper>{art && art.map(renderSlide)}</Wrapper>;
};

ArtCarousel.propTypes = {
  artworks: PropTypes.array,
  playlistId: PropTypes.string,
  align: PropTypes.string,
  hasFocus: PropTypes.bool.isRequired,
  slideWidth: PropTypes.number,
  compact: PropTypes.bool,
  page: PropTypes.string.isRequired,
  onNavigateUp: PropTypes.func,
  onNavigateDown: PropTypes.func,
  onNavigateExit: PropTypes.func,
  onNavigateBack: PropTypes.func,
};

ArtCarousel.defaultProps = {
  artworks: [],
  playlistId: null,
  align: 'left',
  slideWidth: 50,
  compact: false,
  onNavigateUp: () => {},
  onNavigateDown: () => {},
  onNavigateExit: () => {},
  onNavigateBack: () => {},
};

export default ArtCarousel;
