import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import Cookies from 'js-cookie';
import { bdAuth, bdReq } from '@blackdove/utils';
import { Spinner } from '@blackdove/ui';

import { getDeviceId } from '../../util/device';

// Actions
import { FETCH_DEVICE_SUCCESS } from '../../store/constants/deviceConstants';
import { generateDeviceId } from '../../store/actions/appActions';
import { fetchDevice } from '../../store/actions/deviceActions';
import { fetchUser, clearUser } from '../../store/actions/userActions';
import {
  fetchFeaturedPlaylist,
  fetchUserPlaylists,
  fetchActivePlaylist,
  clearFeaturedPlaylist,
  clearUserPlaylists,
  clearActivePlaylist,
} from '../../store/actions/playlistActions';
import { fetchSubscription, clearSubscription } from '../../store/actions/subscriptionActions';

// Styled Components
import { SplashPage, SplashWrapper, Logo } from './styles';

const Splash = () => {
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const activePlaylist = useSelector((state) => state.activePlaylist);

  useEffect(() => {
    const setupApp = async () => {
      try {
        const vizioDeviceId = getDeviceId();
        if (!app.vizioDeviceId) {
          dispatch(generateDeviceId(vizioDeviceId));
        }

        const deviceResult = await dispatch(fetchDevice(app.vizioDeviceId));

        if (deviceResult.type === FETCH_DEVICE_SUCCESS) {
          const emailId = deviceResult.payload._embedded.user.email;

          // TODO: Move this into a redux action.
          if (!bdAuth.getToken()) {
            const response = await bdReq.post('/emailLogin', { emailId });
            if (response.success) {
              bdAuth._storeToken(response.body);
            }
          }

          await dispatch(fetchUser());
          await dispatch(fetchSubscription());
          await dispatch(fetchFeaturedPlaylist());
          const playlists = await dispatch(fetchUserPlaylists());

          let targetPlaylistId;

          if (activePlaylist?.data?.id) {
            targetPlaylistId = activePlaylist?.data?.id;
          } else {
            targetPlaylistId = playlists?.payload?.[0]?.id;
          }

          await dispatch(fetchActivePlaylist(targetPlaylistId));

          setTimeout(() => {
            navigate(`/playlist/${targetPlaylistId}`);
          }, 10);
        } else {
          dispatch(clearFeaturedPlaylist());
          dispatch(clearUserPlaylists());
          dispatch(clearActivePlaylist());
          dispatch(clearUser());
          dispatch(clearSubscription());
          Cookies.remove('auth__token');
          await dispatch(fetchFeaturedPlaylist());
          setTimeout(() => {
            navigate('/featured');
          }, 10);
        }
      } catch (e) {
        console.log(e);
      }
    };

    setupApp();
  }, []);

  return (
    <SplashPage>
      <SplashWrapper>
        <Logo src="https://assets.blackdove.com/images/blackdove-logo-2020.png" alt="" />
        <Spinner type="ZEN_CIRCLE" color="white" size="90px" />
      </SplashWrapper>
    </SplashPage>
  );
};

export default Splash;
