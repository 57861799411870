import {
  FETCH_MOBILE_QUEUE_REQUEST,
  FETCH_MOBILE_QUEUE_SUCCESS,
  FETCH_MOBILE_QUEUE_FAILURE,
} from '../constants/mobileConstants';

const initialState = { data: {}, errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MOBILE_QUEUE_REQUEST:
      return { ...state, loading: true };
    case FETCH_MOBILE_QUEUE_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: [] };
    }
    case FETCH_MOBILE_QUEUE_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    default:
      return state;
  }
};

export default reducer;
