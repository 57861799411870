import styled from '@emotion/styled';
import QR from 'qrcode.react';
import Button from '../../components/Button';

export const UpgradeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #3d3c3c;
  background: radial-gradient(circle, #3d3c3c 0%, #070707 60%);
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  padding-top: 50px;
  width: 22%;
`;

export const TagLine = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 45px;
  color: white;
  font-size: 30px;
  letter-spacing: 0;
`;

export const BottomApps = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const QRCode = styled(QR)`
  border: 15px solid white;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
`;

export const BackButton = styled(Button)`
  position: absolute;
  top: 75px;
  right: 110px;
`;
