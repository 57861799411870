import styled from '@emotion/styled';
import Button from '../../components/Button';

export const HomeWrapper = styled.div`
  height: 100%;
`;

export const HomeHeader = styled.div`
  height: 12%;
  padding: 20px;
`;

export const HomeLogo = styled.img`
  width: 5.5%;
  margin-right: 20px;
`;

export const HomeButton = styled(Button)`
  margin-right: 25px;
  margin-top: 20px;
`;

export const AccountButton = styled(Button)`
  margin-top: 20px;
`;

export const HomePlaylistTitle = styled.div`
  height: 8%;
`;

export const PlaylistName = styled.h4`
  color: white;
  margin-left: 2em;
  font-size: 1.8em;
  font-weight: 700;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
`;

export const FeaturedCarouselWrapper = styled.div`
  height: 42%;
`;

export const PlaylistSelectorWrapper = styled.div`
  padding: 15px 0;
`;

export const HomeCarouselWrapper = styled.div`
  height: 38%;
`;
