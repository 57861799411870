import { v4 as uuid } from 'uuid';

export const version = '3.0.1';

export const getUUID = () => `VIZIO-${uuid().toUpperCase()}`;

export const getDeviceId = () => {
  // NOTE: This is for backwards compatibility and can be removed once all users are using the new version of the app.
  // Old versions of the app stored the vizioDeviceId in localStorage and this allows the new version of our store
  // to use that existing device id, so that users do not have to re-pair.
  const currentDeviceId = localStorage.getItem('vizioDeviceId');
  localStorage.removeItem('vizioDeviceId');

  if (currentDeviceId !== null) {
    return currentDeviceId;
  }

  const newId = getUUID();
  return newId;
};
