export const FETCH_ACTIVE_PLAYLIST_REQUEST = 'FETCH_ACTIVE_PLAYLIST_REQUEST';
export const FETCH_ACTIVE_PLAYLIST_SUCCESS = 'FETCH_ACTIVE_PLAYLIST_SUCCESS';
export const FETCH_ACTIVE_PLAYLIST_FAILURE = 'FETCH_ACTIVE_PLAYLIST_FAILURE';

export const FETCH_FEATURED_PLAYLIST_REQUEST = 'FETCH_FEATURED_PLAYLIST_REQUEST';
export const FETCH_FEATURED_PLAYLIST_SUCCESS = 'FETCH_FEATURED_PLAYLIST_SUCCESS';
export const FETCH_FEATURED_PLAYLIST_FAILURE = 'FETCH_FEATURED_PLAYLIST_FAILURE';

export const FETCH_USER_PLAYLISTS_REQUEST = 'FETCH_USER_PLAYLISTS_REQUEST';
export const FETCH_USER_PLAYLISTS_SUCCESS = 'FETCH_USER_PLAYLISTS_SUCCESS';
export const FETCH_USER_PLAYLISTS_FAILURE = 'FETCH_USER_PLAYLISTS_FAILURE';

export const CLEAR_FEATURED_PLAYLIST = 'CLEAR_FEATURED_PLAYLIST';
export const CLEAR_USER_PLAYLISTS = 'CLEAR_USER_PLAYLISTS';
export const CLEAR_ACTIVE_PLAYLIST = 'CLEAR_ACTIVE_PLAYLIST';

export const FETCH_HOME_PLAYLIST_REQUEST = 'FETCH_HOME_PLAYLIST_REQUEST';
export const FETCH_HOME_PLAYLIST_SUCCESS = 'FETCH_HOME_PLAYLIST_SUCCESS';
export const FETCH_HOME_PLAYLIST_FAILURE = 'FETCH_HOME_PLAYLIST_FAILURE';
