import styled from '@emotion/styled';
import colors from '../../util/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-top: 20px;
`;

export const PlaylistItem = styled.div`
  display: inline-block;
  width: ${({ itemWidth }) => itemWidth}%;
  padding-left: 25px;
  position: absolute;
  left: ${({ offset }) => offset}%;
  text-align: center;
`;

export const PlaylistImage = styled.img`
  width: 100%;
  height: 100%;
  border: ${({ current, hasFocus }) => (current && hasFocus ? `2px solid ${colors.altAccent}` : '2px solid black')};
  border-radius: 15px;
  display: block;
  box-shadow: ${({ current, hasFocus }) => (current && hasFocus ? `0px 0px 20px ${colors.altAccent}` : 'none')};
`;
