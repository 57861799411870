import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';
import { navigate } from '@reach/router';

// Util
import colors from '../../util/colors';
import { LEFT, RIGHT, ENTER, BACK } from '../../util/remoteButtons';
import { version } from '../../util/device';

// Components
import Button from '../../components/Button';

// Actions
import { deleteDevice } from '../../store/actions/deviceActions';
import { clearFeaturedPlaylist, clearUserPlaylists, clearActivePlaylist } from '../../store/actions/playlistActions';
import { clearUser } from '../../store/actions/userActions';
import { clearSubscription } from '../../store/actions/subscriptionActions';
import { DELETE_DEVICE_SUCCESS } from '../../store/constants/deviceConstants';

// Styled Components
import {
  Wrapper,
  InfoWrapper,
  Logo,
  Info,
  DetailsWrapper,
  DetailLabel,
  DetailValue,
  ButtonWrapper,
  QRWrapper,
  VersionWrapper,
} from './styles';

const Account = () => {
  const dispatch = useDispatch();
  const deviceId = useSelector((state) => state.app.vizioDeviceId);
  const device = useSelector((state) => state.device.data);
  const mobileQueue = useSelector((state) => state.mobileQueue);
  const subscription = useSelector((state) => state.subscription.data.plan);
  const activePlaylist = useSelector((state) => state.activePlaylist);
  const [selectedButton, setSelectedButton] = useState('back');
  const backButton = useRef(null);
  const logoutButton = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      backButton.current.focus();
    }, 800);
  });

  useEffect(() => {
    window.onkeydown = (e) => {
      switch (e.keyCode) {
        case BACK: {
          navigate(`/playlist/${activePlaylist?.data?.id}`);
          break;
        }
        case LEFT: {
          setSelectedButton(() => 'back');
          backButton.current.focus();
          break;
        }
        case RIGHT: {
          setSelectedButton(() => 'logout');
          logoutButton.current.focus();
          break;
        }
        case ENTER: {
          if (selectedButton === 'back') {
            backButton.current.click();
          }

          if (selectedButton === 'logout') {
            logoutButton.current.click();
          }
          break;
        }
        default:
          break;
      }
    };
  });

  const handleLogout = async () => {
    try {
      const response = await dispatch(deleteDevice(device.id));

      if (response.type === DELETE_DEVICE_SUCCESS) {
        dispatch(clearFeaturedPlaylist());
        dispatch(clearUserPlaylists());
        dispatch(clearActivePlaylist());
        dispatch(clearUser());
        dispatch(clearSubscription());
        Cookies.remove('auth__token');
        navigate('/');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Wrapper>
      <InfoWrapper>
        <Logo src="https://assets.blackdove.com/images/blackdove-logo-2020.png" alt="" />
        <Info>
          Go to https://account.blackdove.com to change
          <br />
          your account details and edit your subscription.
        </Info>
        <DetailsWrapper style={{ borderTop: `1px solid ${colors.lighterBase}` }}>
          <DetailLabel>Account:</DetailLabel>
          <DetailValue>{mobileQueue?.data?.device?._embedded?.user?.email}</DetailValue>
        </DetailsWrapper>
        <DetailsWrapper style={{ borderBottom: `1px solid ${colors.lighterBase}`, marginBottom: '40px' }}>
          <DetailLabel>Subscription:</DetailLabel>
          <DetailValue>{subscription?.description}</DetailValue>
        </DetailsWrapper>
        <ButtonWrapper>
          <Button ghost to={`/playlist/${activePlaylist?.data?.id}`} ref={backButton}>
            Back
          </Button>
          <Button ghost onClick={handleLogout} ref={logoutButton}>
            Logout
          </Button>
        </ButtonWrapper>
      </InfoWrapper>
      <QRWrapper>
        <QRCode value={deviceId} size={180} renderAs="svg" />
      </QRWrapper>
      <VersionWrapper> {`v ${version}`} </VersionWrapper>
    </Wrapper>
  );
};

export default Account;
