import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, CLEAR_USER } from '../constants/userConstants';

const initialState = { data: {}, errors: [], loading: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return { ...state, loading: true };
    case FETCH_USER_SUCCESS: {
      return { ...state, loading: false, data: action.payload, errors: [] };
    }
    case FETCH_USER_FAILURE:
      return { ...state, loading: false, errors: action.errors };
    case CLEAR_USER:
      return { ...state, data: {} };
    default:
      return state;
  }
};

export default reducer;
