import { bdReq } from '@blackdove/utils';

import { FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE, CLEAR_USER } from '../constants/userConstants';

const fetchUserRequest = () => ({ type: FETCH_USER_REQUEST });
const fetchUserSuccess = (payload) => ({ type: FETCH_USER_SUCCESS, payload });
const fetchUserFailure = (errors) => ({ type: FETCH_USER_FAILURE, errors });

export const fetchUser = () => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const response = await bdReq.get('/me');
    if (response.success) {
      return dispatch(fetchUserSuccess(response.body));
    }
    return dispatch(fetchUserFailure(response.body));
  } catch (e) {
    return dispatch(fetchUserFailure(e));
  }
};

export const clearUser = () => ({ type: CLEAR_USER });
