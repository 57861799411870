import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SC from 'soundcloud';
import findIndex from 'lodash/findIndex';

import {
  closeSoundCloudStream,
  fetchSoundCloudPlaylist,
  startSoundCloudStream,
} from '../../store/actions/soundCloudActions';

function SoundCloudPlayer() {
  const CLIENT_ID = 'z8LRYFPM4UK5MMLaBe9vixfph5kqNA25';
  const PLAYLIST_ID = '257923617';
  const dispatch = useDispatch();
  const soundCloudState = useSelector((state) => state.soundCloud);
  const soundCloudPlayer = useRef(null);

  useEffect(() => {
    SC.initialize({
      client_id: CLIENT_ID,
    });

    dispatch(fetchSoundCloudPlaylist(PLAYLIST_ID));

    return () => {
      dispatch(closeSoundCloudStream());

      if (soundCloudPlayer.current) {
        soundCloudPlayer.current.pause();
        soundCloudPlayer.current.kill();
        soundCloudPlayer.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const handleTrackPlay = (track) => {
      SC.stream(`/tracks/${track.id}`)
        .then((player) => {
          soundCloudPlayer.current = player;

          soundCloudPlayer.current
            .play()
            .then(() => {
              dispatch(startSoundCloudStream({ currentTrack: track }));

              soundCloudPlayer.current.on('finish', () => {
                const currentTrackIndex = findIndex(soundCloudState.playlistTracks, ['id', track.id]);

                if (currentTrackIndex < soundCloudState.playlistTracks.length - 1) {
                  handleTrackPlay(soundCloudState.playlistTracks[currentTrackIndex + 1]);
                } else {
                  handleTrackPlay(soundCloudState.playlistTracks[0]);
                }
              });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    };

    if (!soundCloudState.isStreaming && soundCloudPlayer.current && soundCloudPlayer.current.getState() === 'playing') {
      // Pause audio stream
      soundCloudPlayer.current.pause();
    } else if (
      soundCloudState.isStreaming &&
      soundCloudPlayer.current &&
      soundCloudPlayer.current.getState() === 'paused'
    ) {
      // Resume audio stream
      soundCloudPlayer.current.play();
    } else if (soundCloudState.isStreaming && !soundCloudPlayer.current) {
      // Start audio stream
      if (soundCloudState.playlistTracks.length > 0) {
        handleTrackPlay(soundCloudState.playlistTracks[0]);
      }
    }
  }, [soundCloudState.isStreaming]);

  return null;
}

export default SoundCloudPlayer;
