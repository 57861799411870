import { bdReq, bdAuth } from '@blackdove/utils';

import {
  FETCH_DEVICE_REQUEST,
  FETCH_DEVICE_SUCCESS,
  FETCH_DEVICE_FAILURE,
  DELETE_DEVICE_REQUEST,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
} from '../constants/deviceConstants';

const fetchDeviceRequest = () => ({ type: FETCH_DEVICE_REQUEST });
const fetchDeviceSuccess = (payload) => ({ type: FETCH_DEVICE_SUCCESS, payload });
const fetchDeviceFailure = (errors) => ({ type: FETCH_DEVICE_FAILURE, errors });

export const fetchDevice = (deviceId) => async (dispatch) => {
  dispatch(fetchDeviceRequest());
  try {
    const response = await bdReq.get(`/devices/${deviceId}`);
    if (response.success) {
      return dispatch(fetchDeviceSuccess(response.body.device));
    }
    return dispatch(fetchDeviceFailure(response.body.message));
  } catch (e) {
    return dispatch(fetchDeviceFailure(e));
  }
};

const deleteDeviceRequest = () => ({ type: DELETE_DEVICE_REQUEST });
const deleteDeviceSuccess = (payload) => ({ type: DELETE_DEVICE_SUCCESS, payload });
const deleteDeviceFailure = (errors) => ({ type: DELETE_DEVICE_FAILURE, errors });

export const deleteDevice = (deviceId) => async (dispatch) => {
  dispatch(deleteDeviceRequest());
  try {
    const tokens = bdAuth.getToken();
    const response = await bdReq.delete(`/users/${tokens.user}/devices/${deviceId}`);
    if (response.success) {
      return dispatch(deleteDeviceSuccess(response.body));
    }
    return dispatch(deleteDeviceFailure(response.body.message));
  } catch (e) {
    return dispatch(deleteDeviceFailure(e));
  }
};
