import styled from '@emotion/styled';
import { Play } from '@emotion-icons/ionicons-sharp';
import Button from '../Button';
import colors from '../../util/colors';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const Slide = styled.div`
  display: inline-block;
  width: ${({ slideWidth }) => slideWidth}%;
  height: 100%;
  position: absolute;
  left: ${({ offset }) => offset}%;
  transition: ${({ animate }) => (animate ? 'all 0.35s ease-in-out' : 'none')};
  border-radius: 15px;
`;

export const SlideSize = styled.div`
  height: 80%;
  background-size: 100% 95%;
`;

export const SlideInner = styled.div`
  border-radius: 1em;
  overflow: hidden;
  height: 95%;
  width: 95%;
`;

export const SlideImage = styled.div`
  width: 100%;
`;

export const SlideArtwork = styled.img`
  height: 100%;
  width: 100%;
  background-size: 100% 95%;
  animation: fadeIn 0.5s;
  border-radius: ${({ current, compact, hasFocus }) => (current && hasFocus && !compact ? '15px 15px 0 0' : '15px')};
`;

export const SlideDetails = styled.div`
  color: white;
  padding: 20px 25px;
  height: 20%;
  align-items: center;
  background: ${colors.lighterBase};
  border-radius: 0 0 15px 15px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const SlideCompactDetails = styled.div`
  position: absolute;
  z-index: 100;
  bottom: 10px;
  left: 20px;
  transition: all 0.35s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const ArtworkName = styled.span`
  display: block;
  color: ${colors.white};
  font-weight: ${({ compact }) => (compact ? '500' : '300')};
  font-size: 14px;
  margin-bottom: ${({ compact }) => (compact ? '8px' : '0')};
`;

export const ArtistName = styled.span`
  display: block;
  color: ${colors.white};
  font-weight: 300;
  font-size: 14px;
`;

export const PlayButton = styled(Button)`
  background: ${colors.accent};
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  right: 50px;
  bottom: 6%;
`;

export const PlayIcon = styled(Play)`
  color: ${colors.white};
  width: 20px;
`;
