import SC from 'soundcloud';
import {
  SOUND_CLOUD_PLAYLIST_FETCH,
  SOUND_CLOUD_STREAM_CLOSE,
  SOUND_CLOUD_STREAM_PAUSE,
  SOUND_CLOUD_STREAM_RESUME,
  SOUND_CLOUD_STREAM_START,
} from '../constants/soundCloudConstants';

export const closeSoundCloudStream = () => ({
  type: SOUND_CLOUD_STREAM_CLOSE,
});

export const saveSoundCloudPlaylist = (payload) => ({
  type: SOUND_CLOUD_PLAYLIST_FETCH,
  payload,
});

export const fetchSoundCloudPlaylist = (id) => (dispatch) => {
  SC.get(`/playlists/${id}`)
    .then((playlist) => {
      dispatch(saveSoundCloudPlaylist({ playlistTracks: playlist.tracks }));
    })
    .catch((error) => {
      console.error(error);
    });
};

export const pauseSoundCloudStream = () => ({
  type: SOUND_CLOUD_STREAM_PAUSE,
});

export const resumeSoundCloudStream = () => ({
  type: SOUND_CLOUD_STREAM_RESUME,
});

export const startSoundCloudStream = (payload) => ({
  type: SOUND_CLOUD_STREAM_START,
  payload,
});
