import React from 'react';
import { Global, css } from '@emotion/core';

const styles = css`
  #root {
    width: 100vh !important;
    height: 100vw !important;
    transform: rotate(-90deg) translateX(39vh) translateY(22vw); // Magic numbers that work to keep it full screen, not sure how.
  }
`;

const PortraitStyles = () => <Global styles={styles} />;

export default PortraitStyles;
