import React from 'react';
import PropTypes from 'prop-types';

import { StyledA, StyledButton, StyledLink } from './styles';

const Button = React.forwardRef(({ href, to, children, ...props }, ref) => {
  if (href) {
    return (
      <StyledA ref={ref} href={href} {...props}>
        {children}
      </StyledA>
    );
  }

  if (to) {
    return (
      <StyledLink ref={ref} to={to} {...props}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton ref={ref} {...props}>
      {children}
    </StyledButton>
  );
});

Button.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  type: PropTypes.string,
};

Button.defaultProps = {
  href: '',
  to: '',
  type: 'button',
};

export default Button;
