import { bdReq, bdAuth } from '@blackdove/utils';

import {
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  CLEAR_SUBSCRIPTION,
} from '../constants/subscriptionConstants';

const fetchSubscriptionRequest = () => ({ type: FETCH_SUBSCRIPTION_REQUEST });
const fetchSubscriptionSuccess = (payload) => ({ type: FETCH_SUBSCRIPTION_SUCCESS, payload });
const fetchSubscriptionFailure = (errors) => ({ type: FETCH_SUBSCRIPTION_FAILURE, errors });

export const fetchSubscription = () => async (dispatch) => {
  dispatch(fetchSubscriptionRequest());
  try {
    const tokens = bdAuth.getToken();
    const response = await bdReq.get(`/users/${tokens.user}/subscriptions`);
    if (response.success) {
      return dispatch(fetchSubscriptionSuccess(response.body));
    }
    return dispatch(fetchSubscriptionFailure('Could not fetch subscription'));
  } catch (e) {
    return dispatch(fetchSubscriptionFailure(e));
  }
};

export const clearSubscription = () => ({ type: CLEAR_SUBSCRIPTION });
