import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LEFT, RIGHT, UP, DOWN, ENTER, EXIT } from '../../util/remoteButtons';

import { Wrapper, PlaylistItem, PlaylistImage } from './styles';

const reorderPlaylists = (playlists, targetIndex, centerIndex) => {
  const items = playlists.slice(0);

  if (targetIndex > centerIndex) {
    const offset = targetIndex - centerIndex;
    const playlistsToAppend = items.splice(0, offset);
    items.push(...playlistsToAppend);
  } else if (targetIndex < centerIndex) {
    const offset = centerIndex - targetIndex;
    const playlistsToPrepend = items.splice(-1 + -offset, offset);
    items.unshift(...playlistsToPrepend);
  }

  return items;
};

const PlaylistCarousel = ({
  playlists,
  startingPlaylist,
  hasFocus,
  onSelectPlaylist,
  onNavigateUp,
  onNavigateDown,
  onNavigateExit,
}) => {
  const [orderedPlaylists, setOrderedPlaylists] = useState(playlists);
  const [selectedPlaylist, setSelectedPlaylist] = useState(playlists.findIndex((pl) => pl.id === startingPlaylist));

  useEffect(() => {
    const targetIndex = orderedPlaylists.findIndex((pl) => pl.id === startingPlaylist);
    const centerIndex = Math.floor(orderedPlaylists.length / 2);
    const items = reorderPlaylists(orderedPlaylists, targetIndex, centerIndex);

    setOrderedPlaylists(() => items);
    setSelectedPlaylist(() => centerIndex);
  }, []);

  // When playlists change, reset the playlist order
  useEffect(() => {
    const targetIndex = playlists.findIndex((pl) => pl.id === startingPlaylist);
    const centerIndex = Math.floor(playlists.length / 2);
    const items = reorderPlaylists(playlists, targetIndex, centerIndex);

    setOrderedPlaylists(() => items);
    setSelectedPlaylist(() => centerIndex);
  }, [playlists]);

  useEffect(() => {
    if (hasFocus) {
      window.onkeydown = (e) => {
        switch (e.keyCode) {
          case LEFT: {
            const items = orderedPlaylists.slice(0);
            items.unshift(items.pop());
            setOrderedPlaylists(() => items);
            break;
          }
          case RIGHT: {
            const items = orderedPlaylists.slice(0);
            items.push(items.shift());
            setOrderedPlaylists(() => items);
            break;
          }
          case ENTER: {
            onSelectPlaylist(orderedPlaylists[selectedPlaylist]?.id);
            break;
          }
          case UP: {
            onNavigateUp();
            break;
          }
          case DOWN: {
            onNavigateDown();
            break;
          }
          case EXIT: {
            onNavigateExit();
            break;
          }
          default:
            break;
        }
      };
    }
  });

  const itemWidth = 20;

  const baseOffset = selectedPlaylist * -itemWidth + (100 - itemWidth) / 2;

  const renderItem = (playlist, index) => {
    const current = orderedPlaylists[selectedPlaylist]?.id === playlist?.id;
    const offset = baseOffset + itemWidth * index;

    return (
      <PlaylistItem
        key={`playlist-carousel-item-${playlist?.id}-${playlist?.name}`}
        offset={offset}
        itemWidth={itemWidth}
      >
        <PlaylistImage src={playlist?.media?.image?.low} current={current} hasFocus={hasFocus} />
      </PlaylistItem>
    );
  };

  return <Wrapper>{orderedPlaylists.map(renderItem)}</Wrapper>;
};

PlaylistCarousel.propTypes = {
  playlists: PropTypes.array,
  startingPlaylist: PropTypes.string,
  hasFocus: PropTypes.bool.isRequired,
  onSelectPlaylist: PropTypes.func,
  onNavigateUp: PropTypes.func,
  onNavigateDown: PropTypes.func,
  onNavigateExit: PropTypes.func,
};

PlaylistCarousel.defaultProps = {
  playlists: [],
  startingPlaylist: null,
  onSelectPlaylist: () => {},
  onNavigateUp: () => {},
  onNavigateDown: () => {},
  onNavigateExit: () => {},
};

export default PlaylistCarousel;
