import styled from '@emotion/styled';
import { ChevronBack } from '@emotion-icons/ionicons-sharp';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const BackIcon = styled(ChevronBack)`
  color: white;
  width: 30px;
`;

export const DetailPage = styled.div`
  background: url(/assets/img/bg-blur.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Model = styled.div`
  width: 60%;
  color: white;
  text-align: justify;
  background-color: transparent;
  margin: auto;
`;

export const HeaderLine = styled.h4`
  font-size: 2em;
  font-weight: 700;
`;
export const Paragraph = styled.p`
  font-size: 1.2em;
  font-weight: 500;
`;

export const H5 = styled.h5`
  font-size: 1.5em;
  font-weight: 500;
`;

export const Ul = styled.ul`
  font-size: 0.9em;
  font-weight: 400;
`;
