import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { StyledSoundCloudButton } from './styles';
import { SoundCloudIcon } from '../../assets/icons';

const SoundCloudButton = forwardRef((props, ref) => {
  const soundCloudState = useSelector((state) => state.soundCloud);

  return (
    <StyledSoundCloudButton ref={ref} {...props}>
      <SoundCloudIcon fill={soundCloudState.isStreaming ? '#ff5722' : '#e2e2e2'} />
    </StyledSoundCloudButton>
  );
});

export default SoundCloudButton;
