import {
  SOUND_CLOUD_PLAYLIST_FETCH,
  SOUND_CLOUD_STREAM_CLOSE,
  SOUND_CLOUD_STREAM_PAUSE,
  SOUND_CLOUD_STREAM_RESUME,
  SOUND_CLOUD_STREAM_START,
} from '../constants/soundCloudConstants';

const initialState = {
  currentTrack: null,
  isStreaming: false,
  playlistTracks: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOUND_CLOUD_PLAYLIST_FETCH: {
      return {
        ...state,
        currentTrack: null,
        isStreaming: false,
        playlistTracks: action.payload.playlistTracks,
      };
    }
    case SOUND_CLOUD_STREAM_CLOSE: {
      return {
        ...state,
        currentTrack: null,
        isStreaming: false,
        playlistTracks: [],
      };
    }
    case SOUND_CLOUD_STREAM_PAUSE: {
      return {
        ...state,
        isStreaming: false,
      };
    }
    case SOUND_CLOUD_STREAM_RESUME: {
      return {
        ...state,
        isStreaming: true,
      };
    }
    case SOUND_CLOUD_STREAM_START: {
      return {
        ...state,
        currentTrack: action.payload.currentTrack,
        isStreaming: true,
      };
    }
    default:
      return state;
  }
};
