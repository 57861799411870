import styled from '@emotion/styled';
import Button from '../../components/Button';

export const ErrorWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #3d3c3c;
  background: radial-gradient(circle, #3d3c3c 0%, #070707 60%);
`;

export const Logo = styled.img`
  display: block;
  margin: 0 auto;
  padding-top: 150px;
  width: 40%;
`;

export const ErrorText = styled.div`
  display: block;
  margin: 0 auto;
  text-align: center;
  margin-top: 45px;
  color: white;
  font-size: 30px;
  letter-spacing: 0;
`;

export const RelaunchButton = styled(Button)`
  position: absolute;
  top: 75%;
  left: 45%;
  font-size: 1.2rem;
`;

export const ErrorDiv = styled.div`
  z-index: 9999;
  position: absolute;
  top: 88%;
  left: 2%;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  background: rgb(238, 30, 35);
  padding: 1%;
  border-radius: 18px;
  opacity: 0;
  transition: 1s;
`;
