import { combineReducers } from 'redux';

import app from './reducers/appReducer';
import soundCloud from './reducers/soundCloudReducer';
import subscription from './reducers/subscriptionReducer';
import device from './reducers/deviceReducer';
import user from './reducers/userReducer';
import featuredPlaylist from './reducers/featuredPlaylistReducer';
import userPlaylists from './reducers/userPlaylistsReducer';
import activePlaylist from './reducers/activePlaylistReducer';
import artwork from './reducers/artworkReducer';
import mobileQueue from './reducers/mobileReducer';
import homePlaylist from './reducers/homePlaylistReducer';

const rootReducer = combineReducers({
  app,
  user,
  soundCloud,
  subscription,
  device,
  featuredPlaylist,
  userPlaylists,
  activePlaylist,
  artwork,
  mobileQueue,
  homePlaylist,
});

export default rootReducer;
