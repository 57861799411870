import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PortraitStyles from '../../util/portraitStyles';

import { LayoutWrapper } from './styles';

const Layout = ({ children }) => {
  const device = useSelector((state) => state.device);
  return (
    <LayoutWrapper>
      {['portrait', 'vertical'].includes(device.data?.orientation) && <PortraitStyles />}
      {children}
    </LayoutWrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
